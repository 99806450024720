<template>
  <div class="Details">
    <competitive-details-layout>
      <template #header>
        <OnxSpotlightHeaderNav @breadcrumbs-home-click="goToOverview">
          <template #tabs>
            <OnxTabsContext :selected-tab="selectedGeocoding">
              <OnxTabs @change="onTabChange">
                <OnxTab
                  v-for="grouping in [...groupingsOS, ...groupingsCustom]"
                  :id="grouping.id.toString()"
                  :key="grouping.id"
                  :value="grouping.id.toString()"
                >
                  {{ getTabNameForGrouping(grouping) }}
                </OnxTab>
              </OnxTabs>
            </OnxTabsContext>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #map>
        <div class="map-container">
          <OnxCollapsible :collapsed="!matches.laptop.value">
            <template #default="collapsible">
              <CompetitiveTile
                :metric-type="nationalMetricBoxData.metricType"
                :metric-kind="nationalMetricBoxData.metricKind"
                :metric-unit="nationalMetricBoxData.metricUnit"
                :metric-category="nationalMetricBoxData.metricCategory"
                :bigger-better="nationalMetricBoxData.biggerBetter"
                :title="nationalMetricBoxData.title"
                :ranks="nationalMetricBoxData.ranks"
                :options="nationalMetricBoxData.options"
                :granularity="nationalMetricBoxData.granularity"
                :is-loading="Boolean(nationalMetricBoxData.loading)"
                :collapsed="collapsible.collapsed || !nationalMetricBoxData.ranks"
                :hide-content="collapsible.collapsed || !nationalMetricBoxData.ranks"
                :hide-actions="collapsible.collapsed || !nationalMetricBoxData.ranks"
                :class="{ collapsed: collapsible.collapsed }"
                @header:click="!matches.laptop.value && collapsible.toggle()"
              >
                <template #title="titleProps">
                  <div class="onx-national-card__title-container">
                    <OnxHeadline as="h4" class="onx-national-card__title">
                      {{ (hoveredLocationData?.data && hoveredLocationData?.name) || 'NATIONAL LEVEL' }}

                      <CustomTooltip
                        v-if="isCurrentMetricAwardMetric"
                        :message="tooltipMessages[`availabilityAward_${userGroup}`]"
                        placement="top"
                      >
                        <AwardIcon />
                      </CustomTooltip>
                    </OnxHeadline>

                    <template v-if="!matches.laptop.value">
                      <OnxCaption v-if="collapsible.collapsed" variant="md">
                        {{ titleProps.rank }} / {{ titleProps.value }}
                      </OnxCaption>

                      <ChevronDownIcon
                        class="onx-dropdown-icon"
                        :class="{ 'onx-dropdown-icon--reverse': !collapsible.collapsed }"
                      />
                    </template>
                  </div>
                </template>

                <template v-if="!collapsible.collapsed && !hoveredLocationData" #actions>
                  <OnxButton
                    variant="tertiary"
                    size="sm"
                    :disabled="!hasDrilldownToFocus(currentCountry.key)"
                    @click="drillDownIntoFocus(currentCountry.key)"
                  >
                    <span>Metric drilldown</span>
                    <ChevronRightIcon small />
                  </OnxButton>
                </template>
              </CompetitiveTile>
            </template>
          </OnxCollapsible>

          <ChartWrapper
            :loading="isChartLoading || !shapes.geoJson"
            :empty="isMapEmpty"
            class="Details__mapWrapper"
            disable-title
            disable-export
          >
            <div v-if="compare" class="Details__mapView Details__mapView--ranking" :class="detailsMapViewClass">
              <!-- This is false so the map is empty while this is loading -->
              <RankingMap
                v-model="selectedLocation"
                :geo-json="mapGeoJson"
                :choropleth-data="winners"
                :networks="displayOps"
                :zoomed="parseInt(currentLocation.key) !== currentCountryId"
                :locations-with-rank="locationsWithRank"
                :current-country="currentCountryId"
                :show-features="showFeatures"
                display-rank
                @location-mouseover="setHoveredLocationData"
                @location-mouseout="setHoveredLocationData(null)"
              />
            </div>
            <div v-else class="Details__mapView etails__mapView--choropleth" :class="detailsMapViewClass">
              <ChoroplethMap
                v-model="selectedLocation"
                :geo-json="shapes.geoJson"
                :choropleth-data="networkMapData"
                :choropleth-cities-data="networkCitiesMapData"
                :bigger-is-better="currentMetric.bigger_is_better"
                :colour-scale-labels="colourScaleLabels"
                :zoomed="parseInt(currentLocation.key) !== currentCountryId"
                display-rank
                @location-mouseover="setHoveredLocationData"
                @location-mouseout="setHoveredLocationData(null)"
              />
            </div>
          </ChartWrapper>
        </div>
      </template>

      <br />

      <template #charts>
        <ChartWrapperExtended
          v-if="showTrends"
          :loading="pending"
          :empty="isChartEmpty"
          :picture-meta="exportInfo"
          :selected-point="selectedPoint"
          :title="'Competitive Trends'"
          :title-labels="exportInfo"
          :show-confidence-rate="showConfidenceRate"
          :unit="unit"
          :compare="displayNational"
          :chart-data="trendSeries"
          enable-compare
          @compare="displayNational = !displayNational"
        >
          <line-chart
            :data-set="Object.values(trendSeries)"
            :compare-set="nationalValues"
            :date="date"
            :title="currentMetric && currentMetric.name"
            :aggregation="currentAgg && currentAgg.name && `${currentAgg.name} agg.`"
            :y-axis-unit="unit"
            :end-date="lastDateAvailable"
            :show-confidence-rate="showConfidenceRate"
            :show-line-icons="true"
            :height="350"
            :show-compare="showCompare"
            chart-id="trend"
            class="Details__chartView"
            show-rank-gaps
            @point="navigateToPoint"
          />
        </ChartWrapperExtended>

        <template v-else-if="!mapLoading && rankedGeographies.length">
          <div class="container--spacing-x container--spacing-b">
            <OnxPaper class="Details__content__title-bar">
              <DownloadFileIcon
                v-if="displayList"
                button
                data-test-id="spotlight-details_list-view_csv-export-btn"
                @click="listViewCsvExport"
              />
              <AlphaSortIcon
                button
                :active="sortingByName"
                :ascending="sortingByName ? sortOrder === 1 : true"
                data-test-id="spotlight-details_sort-btn"
                @click="sortAlphabetically"
              />
              <GridIcon
                button
                :active="displayGrid"
                data-test-id="spotlight-details_display-grid-btn"
                @click="setDisplayMode('grid')"
              />
              <ListIcon
                button
                :active="displayList"
                data-test-id="spotlight-details_display-list-btn"
                @click="setDisplayMode('list')"
              />
            </OnxPaper>
          </div>

          <div v-if="displayGrid" class="region-city-metric-box-grid onx-grid fluid">
            <OnxMetricBox
              v-for="(area, i) in sortedRankedGeographies"
              :key="area.index"
              :bigger-better="currentMetric.bigger_is_better"
              :ranks="area.item"
              :min-and-max="minAndMax(area.item)"
            >
              <template #header>
                <OnxHeadline as="h4">
                  {{ area.properties.name }}
                </OnxHeadline>
                <OnxCaption>#{{ i + 1 }}/{{ rankedGeographies.length }} in list</OnxCaption>
              </template>

              <template #footer>
                <OnxButton variant="tertiary" size="sm" @click="update({ location: area.properties.id })">
                  <span>View details</span>
                  <ChevronRightIcon small />
                </OnxButton>

                <OnxButton
                  variant="tertiary"
                  size="sm"
                  :disabled="!hasDrilldownToFocus(area.properties.id)"
                  @click="drillDownIntoFocus(area.properties.id)"
                >
                  <span>Metric drilldown</span>
                  <ChevronRightIcon small />
                </OnxButton>
              </template>
            </OnxMetricBox>
          </div>

          <div v-if="displayList" class="container--spacing-x container--spacing-b">
            <OnxPaper class="Details__content__list-view">
              <table class="onx-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      {{ selectedGeocoding === '3' ? 'City' : 'Region' }}
                    </th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Score</th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Confidence Interval</th>
                    <th scope="colgroup" :colspan="listViewOperators.length">Rank</th>
                    <th scope="col" />
                  </tr>
                  <tr>
                    <th scope="col" />
                    <th scope="col" />

                    <th
                      v-for="operator in listViewOperators"
                      :key="operator.operatorName"
                      scope="col"
                      class="interactive"
                      :class="{
                        'Details__content__list-view__header--highlighted': isSortingByOperatorProp(
                          operator.network,
                          'value',
                        ),
                      }"
                      @click="sortByOperatorProp(operator.network, 'value')"
                    >
                      <div class="Details__content__list-view__header__content">
                        <OperatorAvatar
                          :background-color="operator.operatorColor"
                          :name="operator.operatorName"
                          :background-style="operator.operatorIsMvno ? 'outline' : 'fill'"
                        />

                        <SortOrderIcon class="visibility-hidden" active :ascending="sortOrder === 1" />
                      </div>
                    </th>

                    <th v-for="operator in listViewOperators" :key="operator.operatorName" scope="col">
                      <OperatorAvatar
                        :background-color="operator.operatorColor"
                        :name="operator.operatorName"
                        :background-style="operator.operatorIsMvno ? 'outline' : 'fill'"
                      />
                    </th>

                    <th
                      v-for="operator in listViewOperators"
                      :key="operator.operatorName"
                      scope="col"
                      class="interactive"
                      :class="{
                        'Details__content__list-view__header--highlighted': isSortingByOperatorProp(
                          operator.network,
                          'rank',
                        ),
                      }"
                      @click="sortByOperatorProp(operator.network, 'rank', 1)"
                    >
                      <div class="Details__content__list-view__header__content">
                        <OperatorAvatar
                          :background-color="operator.operatorColor"
                          :name="operator.operatorName"
                          :background-style="operator.operatorIsMvno ? 'outline' : 'fill'"
                        />

                        <SortOrderIcon class="visibility-hidden" active :ascending="sortOrder === 1" />
                      </div>
                    </th>

                    <th scope="col" />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="row in listViewDataRows" :key="row.index">
                    <td>{{ row.index }}.</td>
                    <td>{{ row.properties.name }}</td>
                    <td v-for="(score, i) in row.scores" :key="`${row.properties.name}-${score}-${i}`">
                      {{ score }}
                    </td>
                    <td
                      v-for="(interval, i) in row.confidenceIntervals"
                      :key="`${row.properties.name}-${interval}-${i}`"
                    >
                      {{ interval }}
                    </td>
                    <td v-for="(rank, i) in row.ranks" :key="`${row.properties.name}-${rank}-${i}`">
                      {{ getRankLabel(rank) }}
                    </td>
                    <td>
                      <VDropdown :distance="6">
                        <span>
                          <MenuDotsIcon button data-test-id="spotlight-details_list-view_menu-btn" />
                        </span>

                        <template #popper>
                          <OnxPaper :depth="3">
                            <OnxList>
                              <OnxListItem
                                extra-x-padding
                                data-test-id="spotlight-details_list-view_view-details-btn"
                                @click="update({ location: row.properties.id })"
                              >
                                <OnxListItemText size="sm"> View details </OnxListItemText>
                              </OnxListItem>

                              <OnxListItem
                                extra-x-padding
                                :disabled="!hasDrilldownToFocus(row.properties.id)"
                                data-test-id="spotlight-details_list-view_metric-drilldown-btn"
                                @click="drillDownIntoFocus(row.properties.id)"
                              >
                                <OnxListItemText size="sm"> Metric drilldown </OnxListItemText>
                              </OnxListItem>
                            </OnxList>
                          </OnxPaper>
                        </template>
                      </VDropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <OnxParagraph size="small" class="Details__content__list-view__copyright">
                {{ copyrightMessage }}
              </OnxParagraph>
            </OnxPaper>
          </div>
        </template>
      </template>
    </competitive-details-layout>
  </div>
  <SimpleDialog :open="isOpenFocusPromotionDialog" @update:open="handlePromotionDialogUpdate">
    <template #default>
      <FocusPromotion />
    </template>
  </SimpleDialog>
</template>

<script>
import { isBefore } from 'date-fns';
import get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';
import OperatorAvatar from '@/components/OperatorAvatar';
import SimpleDialog from '@/components/SimpleDialog';
import CompetitiveTile from '@/components/comparison/CompetitiveTile';
import CompetitiveDetailsLayout from '@/components/competitive/CompetitiveDetailsLayout';
import OnxButton from '@/components/onx/OnxButton';
import OnxCollapsible from '@/components/onx/OnxCollapsible';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxMetricBox from '@/components/onx/OnxMetricBox';
import OnxPaper from '@/components/onx/OnxPaper';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useMetricBoxData from '@/components/onx/composables/useMetricBoxData';
import FocusPromotion from '@/components/onx/FocusPromotion.vue';
import useUser from '@/components/onx/composables/useUser';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav';
import AlphaSortIcon from '@/components/onx/icons/AlphaSortIcon';
import AwardIcon from '@/components/onx/icons/AwardIcon';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon';
import GridIcon from '@/components/onx/icons/GridIcon';
import ListIcon from '@/components/onx/icons/ListIcon';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon';
import SortOrderIcon from '@/components/onx/icons/SortOrderIcon';
import { OnxTab, OnxTabs, OnxTabsContext } from '@/components/onx/tabs';
import OnxCaption from '@/components/onx/typography/OnxCaption';
import OnxHeadline from '@/components/onx/typography/OnxHeadline';
import OnxParagraph from '@/components/onx/typography/OnxParagraph.vue';
import { ChartWrapper, ChartWrapperExtended, ChoroplethMap, LineChart, RankingMap } from '@/components/visual';
import { metricMinAndMax } from '@/components/visual/map/helpers';
import { SPOTLIGHT_TO_FOCUS_ALLOWED, COPYRIGHT, OS_GEOCODINGS, TAB_NAMES_BY_GRANULARITY } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import router from '@/router';
import { getSafeDate } from '@/utils/date';
import { exportToCsv } from '@/utils/files';
import { customGroupingsFilter, osGroupingsFilter } from '@/utils/groupings';
import { isAwardMetric } from '@/utils/metrics';
import { getRankLabel } from '@/utils/viewHelpers';
import { CustomTooltip } from '@/components/tooltip';

export default {
  name: 'CompetitiveDetails',
  components: {
    OnxParagraph,
    DownloadFileIcon,
    ChevronDownIcon,
    ChoroplethMap,
    RankingMap,
    ChartWrapper,
    ChartWrapperExtended,
    CompetitiveDetailsLayout,
    LineChart,
    GridIcon,
    AlphaSortIcon,
    SortOrderIcon,
    ListIcon,
    MenuDotsIcon,
    OnxList,
    OnxListItem,
    OnxListItemText,
    OnxSpotlightHeaderNav,
    OnxPaper,
    OnxTabs,
    OnxTab,
    OnxTabsContext,
    OnxMetricBox,
    OnxButton,
    OnxHeadline,
    OnxCaption,
    ChevronRightIcon,
    CompetitiveTile,
    OnxCollapsible,
    OperatorAvatar,
    SimpleDialog,
    FocusPromotion,
    CustomTooltip,
    AwardIcon,
  },
  beforeRouteUpdate(to, from, next) {
    // give the $router a way to know about all params
    this.setRouteParam(to.params);

    if (
      router.currentRoute.value.params.countryid !== to.params.countryid ||
      router.currentRoute.value.params.metric !== to.params.metric ||
      router.currentRoute.value.params.date !== to.params.date ||
      router.currentRoute.value.params.agg !== to.params.agg
    ) {
      this.setNational({
        location: to.params.countryid,
        date: to.params.date,
        metric: to.params.metric,
        aggregation: to.params.agg,
      });
    }

    // linked navigation to handle new regions and new metrics
    if (
      this.location !== to.params.location ||
      this.selectedGeocoding !== to.params.geocoding ||
      this.metric !== to.params.metric
    ) {
      this.mapLoading =
        this.metric !== to.params.metric ||
        this.selectedGeocoding !== to.params.geocoding ||
        !(
          this.mapData.find((p) => to.params.location === `${p.location}`) ||
          (this.country === to.params.country && to.params.countryid === to.params.location)
        );

      this.setCiTrend({
        metric: to.params.metric,
        location: to.params.location,
        country: to.params.countryid,
        countryISO3: to.params.country,
        agg: to.params.agg,
        date: to.params.date,
        geocoding: to.params.geocoding,
        network: to.params.network,
      }).then((reply) => {
        if (reply && this.mapLoading) {
          this.setMap({
            metric: to.params.metric,
            agg: to.params.agg,
            geocoding: to.params.geocoding,
            country: to.params.country,
            date: to.params.date,
            altVersion: true,
          });
          this.mapLoading = false;
        }
      });
    } else {
      if (to.params.date === this.date) {
        this.setCiTrend({
          metric: to.params.metric,
          location: to.params.location,
          country: to.params.countryid,
          countryISO3: to.params.country,
          agg: to.params.agg,
          date: to.params.date,
          geocoding: to.params.geocoding,
          network: to.params.network,
        });
      }

      if (
        this.country !== to.params.country ||
        this.metric !== to.params.metric ||
        this.network !== to.params.network ||
        this.agg !== to.params.agg ||
        (this.geocoding !== to.params.geocoding && !this.mapIsUnchanged(this.geocoding, to.params.geocoding)) ||
        this.date !== to.params.date
      ) {
        this.setMap({
          metric: to.params.metric,
          agg: to.params.agg,
          geocoding: to.params.geocoding,
          country: to.params.country,
          date: to.params.date,
          altVersion: true,
        });
        this.mapLoading = false;
      }
    }

    // Reset metric geographies focused view
    this.setFocusedView(true);

    next();
  },
  props: {
    location: {
      type: String,
      default: '212',
    },
    country: {
      type: String,
      default: 'USA',
    },
    countryid: {
      type: String,
      default: '212',
    },
    geocoding: {
      type: String,
      default: '1',
    },
    agg: {
      type: String,
      default: '90days',
    },
    metric: {
      type: String,
      default: 'availability_lte',
    },
    date: {
      type: String,
      default: '',
    },
    network: {
      type: String,
      default: 'all',
    },
  },
  setup() {
    const matches = useBreakpoints();
    const { createMetricBoxData } = useMetricBoxData('nationalStats');
    const { user } = useUser();
    return {
      matches,
      createMetricBoxData,
      user,
    };
  },
  data() {
    return {
      displayAll: false,
      displayNational: false,
      mapLoading: false,
      isExpanded: false,
      tooltipMessages: TOOLTIP_MESSAGES,
      videoTooltip: TOOLTIP_MESSAGES.videoexperience,
      confidenceIntervals: true,
      hoveredLocationData: null,
      // grid | list
      displayMode: 'grid',
      sortBy: null,
      sortOrder: -1, // - 1 for desc, 1 for asc
      isOpenFocusPromotionDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'locations',
      'polygons',
      'operators',
      'metrics',
      'mapData',
      'shapes',
      'nationalStats',
      'dashboardInfo',
      'ciConfig',
    ]),
    ...mapGetters({
      orderValues: 'competitive/getOrderedValues',
      actualTimeRange: 'competitive/actualTimeRange',
      displayOps: 'charts/selectedNetworkOperators',
      isEmpty: 'competitive/isEmpty',
      compare: 'competitive/compare',
      winners: 'competitive/winners',
      locationsWithRank: 'competitive/locationsWithRank',
      currentMetric: 'metrics/primaryMetric',
      lastDateAvailable: 'auth/getLastDateAvailable',
      empty: 'chart/chartEmpty',
      mapEmpty: 'mapData/chartEmpty',
      pending: 'chart/chartPending',
      mapPending: 'mapData/chartPending',
      currentLocation: 'location/currentLocation',
      currentCountry: 'location/currentCountry',
      getTitleLabels: 'charts/getTitleLabels',
      minDate: 'charts/organizationStartDate',
      currentAgg: 'competitive/agg',
      dateRange: 'competitive/dateRange',
      trendSeries: 'competitive/trendSeries',
      national: 'competitive/national',
      focusData: 'competitive/focusChart',
      showConfidenceRate: 'dashboard/getConfidenceState',
      selectedPoint: 'dashboard/selectedPoint',
      unit: 'metrics/primaryUnit',
      networkMapData: 'competitive/networkMapData',
      networkCitiesMapData: 'competitive/networkCitiesMapData',
      networkStats: 'competitive/networkStats',
      networkItems: 'competitive/networkItems',
      locationsInCountry: 'location/inCountry',
      showWarning: 'competitive/showWarning',
      showRegionsAndCities: 'competitive/regionsAndCities',
      groupings: 'location/byGroupings',
      timeRange: 'competitive/timeRange',
      userGroup: 'competitive/userGroup',
      rankedGeographies: 'competitive/rankedGeographies',
      homeNetwork: 'charts/homeNetwork',
      confidenceState: 'dashboard/getConfidenceState',
    }),
    copyrightMessage() {
      return COPYRIGHT;
    },
    sortingByName() {
      return this.sortBy === 'properties.name';
    },
    displayGrid() {
      return this.displayMode === 'grid';
    },
    displayList() {
      return this.displayMode === 'list';
    },
    groupingsOS() {
      return this.groupings.filter(osGroupingsFilter);
    },
    groupingsCustom() {
      return this.groupings.filter(customGroupingsFilter);
    },
    showTrends() {
      return parseInt(this.selectedGeocoding, 10) === OS_GEOCODINGS.countries || !this.isNationalLevel;
    },
    nationalMetricBoxData() {
      let data;

      if (this.hoveredLocationData?.data) {
        data = this.hoveredLocationData.data.map((datum) => ({
          ...datum,
          metric: this.currentMetric.key,
          canonical_network_id: parseInt(datum.network),
        }));
      } else {
        data = (this.nationalStats || []).filter((ns) => ns.date === this.date);
      }

      const ranksSource = this.orderValues(data, this.currentMetric).filter((rankSource) => {
        return !rankSource.operator.is_mvno; // Don't show MVNOs on the national metric box in the map view
      });
      return this.createMetricBoxData(ranksSource, this.currentMetric, this.ciConfig.compareTo);
    },
    isCurrentMetricAwardMetric() {
      return isAwardMetric(this.currentMetric);
    },
    isChartEmpty() {
      return this.isEmpty || this.empty;
    },
    title() {
      return `${this.currentMetric.name} Metrics`;
    },
    isChartLoading() {
      const basicCondition = this.mapPending || this.mapLoading;
      let extraCondition;
      if (this.compare) {
        extraCondition = this.mapGeoJson && this.winners.length && this.operators.length;
      } else {
        extraCondition = this.mapGeoJson && this.networkMapDataAvailable && this.locations.length;
      }
      return basicCondition || !extraCondition;
    },
    currentCountryId() {
      return parseInt(this.countryid);
    },
    selectedLocation: {
      get() {
        return parseInt(this.currentLocation.key);
      },
      set(location) {
        this.update({
          location,
          // geocoding: get(this.locationsInCountry.find(r => r.key === `${region}`), 'granularityId', router.currentRoute.value.params.geocoding)
        });
      },
    },
    networkMapDataAvailable() {
      return this.networkMapData.length || this.networkCitiesMapData.length;
    },
    useRegionsData() {
      return this.shapes.geoJson && this.shapes.geoJson.features.length > 0;
    },
    isMapEmpty() {
      return this.mapEmpty;
    },
    mapGeoJson() {
      return {
        ...this.shapes.geoJson,
        features:
          this.shapes.geoJson?.features.map((f) => {
            const dataWithoutMvnos = f.item.filter((item) => !item.operatorIsMvno);
            return {
              ...f,
              item: dataWithoutMvnos,
            };
          }) || [],
      };
    },
    currentGeocoding() {
      return this.polygons.find((l) => l.id === this.geocoding);
    },
    colourScaleLabels: function () {
      const labelUnit = this.unit === '0 - 100' ? '' : this.unit;
      return [
        `${this.networkStats.maxValue}${labelUnit}`,
        `${this.networkStats.median}${labelUnit}`,
        `${this.networkStats.minValue}${labelUnit}`,
      ];
    },
    exportInfo() {
      return {
        ...this.getTitleLabels(
          this.currentMetric,
          this.actualTimeRange.start,
          router.currentRoute.value.params.date === 'latest'
            ? this.lastDateAvailable
            : router.currentRoute.value.params.date,
          true,
        ),
        product: 'competitive',
        warning: this.showWarning,
        name: this.currentMetric.name,
      };
    },
    detailsMapViewClass() {
      return {
        'Details__mapView--expanded': this.isExpanded,
      };
    },
    nationalValues() {
      if (this.displayNational) {
        return Object.values(this.national);
      } else {
        return null;
      }
    },
    isNationalLevel() {
      return router.currentRoute.value.params.location === router.currentRoute.value.params.countryid;
    },
    showCompare() {
      return this.displayNational && !this.isNationalLevel;
    },
    showFeatures() {
      // we will hide map features when on the national tab,
      // as some of the country polygons e.g. taiwan, hong kong are not detailed enough
      return router.currentRoute.value.params.geocoding != OS_GEOCODINGS.countries;
    },
    sortedRankedGeographies() {
      const results = [...this.rankedGeographies];
      const sortBy = this.sortBy || `operatorsById.${this.homeNetwork.canonical_network_id}.value`;

      const compareStrings = typeof get(results[0], sortBy) === 'string';

      results.sort((a, b) => {
        if (compareStrings) {
          const first = this.sortOrder === 1 ? a : b;
          const second = this.sortOrder === 1 ? b : a;

          return get(first, sortBy).localeCompare(get(second, sortBy));
        } else {
          const minuend = get(a, sortBy) || Infinity;
          const subtrahend = get(b, sortBy) || Infinity;
          const diff = minuend - subtrahend;
          return diff * this.sortOrder;
        }
      });

      return results;
    },
    listViewOperators() {
      const operators =
        this.rankedGeographies[0]?.item.map((operator) => {
          return {
            ...operator,
            canonical_network_id: parseInt(operator.network, 10),
          };
        }) || [];

      if (operators.length <= 1 || operators[0].canonical_network_id === this.homeNetwork.canonical_network_id) {
        return operators;
      }

      // If the home network is not the first operator, move it to the top of the list
      const homeNetworkIndex = operators.findIndex(
        (operator) => operator.canonical_network_id === this.homeNetwork.canonical_network_id,
      );

      // no home network found
      if (homeNetworkIndex === -1) {
        return operators;
      }

      // switch first with homeNetworkIndex
      const temp = operators[0];
      operators[0] = operators[homeNetworkIndex];
      operators[homeNetworkIndex] = temp;

      return operators;
    },
    listViewDataRows() {
      return this.sortedRankedGeographies.map((area, i) => {
        const index = i + 1;
        const { confidenceIntervals, ranks, scores } = this.listViewOperators.reduce(
          (acc, operator) => {
            const currentOperator = area.operatorsById[operator.network];

            acc.scores.push(currentOperator?.value || '-');
            acc.confidenceIntervals.push(`${currentOperator?.lci ?? ''} - ${currentOperator?.uci ?? ''}`);
            acc.ranks.push(currentOperator?.rank);
            return acc;
          },
          { scores: [], confidenceIntervals: [], ranks: [] },
        );

        return {
          index,
          properties: area.properties,
          scores,
          confidenceIntervals,
          ranks,
        };
      });
    },
    selectedGeocoding: {
      get() {
        return router.currentRoute.value.params.geocoding;
      },
      set(granularityId) {
        this.update({
          geocoding: granularityId,
        });
      },
    },
  },
  watch: {
    mapEmpty() {
      // Redirect to national level view if it's the only option available (when switching countries)
      if (router.currentRoute.value.params.geocoding !== '1' && this.groupings.length === 0) {
        this.setMap({
          metric: router.currentRoute.value.params.metric,
          agg: router.currentRoute.value.params.agg,
          geocoding: '1',
          country: router.currentRoute.value.params.country,
          date: router.currentRoute.value.params.date,
          altVersion: true,
        });
      } else {
        this.mapLoading = false;
      }
    },
  },
  mounted() {
    this.selectedGeocoding = router.currentRoute.value.params.geocoding;

    this.setRouteParam(router.currentRoute.value.params);

    this.setNational({
      location: router.currentRoute.value.params.countryid,
      date: router.currentRoute.value.params.date,
      metric: router.currentRoute.value.params.metric,
      aggregation: router.currentRoute.value.params.agg,
    });

    Promise.all([
      this.setCiTrend({
        metric: this.metric,
        location: this.location,
        country: this.countryid,
        agg: this.agg,
        date: this.date,
        geocoding: this.geocoding,
        network: this.network,
        countryISO3: this.country,
      }),
      this.setMap({
        metric: this.metric,
        agg: this.agg,
        geocoding: this.geocoding,
        country: this.country,
        date: this.date,
        altVersion: true,
      }),
    ]).then(() => {
      this.trackRoute('competitive');
      this.setInitialTimeframe();
    });
  },
  methods: {
    ...mapActions([
      'setCiTrend',
      'setMap',
      'setRouteParam',
      'trackRoute',
      'setNational',
      'setFocusedView',
      'setTimeRange',
    ]),
    listViewCsvExport() {
      const headerExtraCols = Array.from({ length: this.listViewOperators.length - 1 }, () => '');
      const headers = [
        '#',
        this.selectedGeocoding === '3' ? 'City' : 'Region',
        'Score',
        ...headerExtraCols,
        'Confidence Interval',
        ...headerExtraCols,
        'Rank',
        ...headerExtraCols,
      ];

      const operatorNames = this.listViewOperators.map((operator) => operator.label);

      const firstRow = ['', '', ...operatorNames, ...operatorNames, ...operatorNames];

      const rows = [headers, firstRow];
      this.listViewDataRows.forEach((row) => {
        const {
          confidenceIntervals,
          index,
          properties: { name },
          ranks,
          scores,
        } = row;
        const rowValues = [index, name, ...scores, ...confidenceIntervals, ...ranks.map((rank) => rank || '-')];

        rows.push(rowValues);
      });

      const { aggregation, endDate, fullTitle, geography, name } = this.exportInfo;
      const filename = `${name || fullTitle} for ${geography} ${aggregation} ${endDate}.csv`;

      exportToCsv(filename, rows);
    },
    sort(by, order = -1) {
      if (this.sortBy === by) {
        this.sortOrder = this.sortOrder * -1;
      } else {
        this.sortBy = by;
        this.sortOrder = order;
      }
    },
    sortAlphabetically() {
      this.sort('properties.name', 1);
    },
    sortByOperatorProp(operatorId, sortBy, sortOrder) {
      this.sort(this.createOperatorsByIdSortString(operatorId, sortBy), sortOrder);
    },
    isSortingByOperatorProp(operatorId, sortBy) {
      return this.sortBy === this.createOperatorsByIdSortString(operatorId, sortBy);
    },
    createOperatorsByIdSortString(operatorId, sortBy) {
      return `operatorsById.${operatorId}.${sortBy}`;
    },
    setDisplayMode(displayMode) {
      this.displayMode = displayMode;
    },
    setHoveredLocationData(data) {
      if (router.currentRoute.value.params.geocoding === '1') {
        return;
      }

      this.hoveredLocationData = data
        ? {
            name: data.locationName,
            data: this.rankedGeographies.find((area) => area.id === data.locationData.location)?.item,
          }
        : null;
    },
    getTabNameForGrouping(grouping) {
      return grouping.client === 'opensignal' ? TAB_NAMES_BY_GRANULARITY[grouping.granularity] : grouping.name;
    },
    goToOverview() {
      const { location } = router.currentRoute.value.params;
      router.push({ name: ROUTES.CompetitiveOverview, params: { location, compareTo: this.ciConfig.compareTo } });
    },
    drillDownIntoFocusUrl(locationKey) {
      const allowed =
        SPOTLIGHT_TO_FOCUS_ALLOWED.includes(this.currentMetric.subtype) &&
        ['5g', 'lte', '3g'].includes(this.currentMetric.type) &&
        ['main'].includes(this.currentMetric.subcategory);

      if (!allowed) {
        return null;
      }

      const queryParam = [
        ['location', locationKey],
        ['metricSubtype', this.currentMetric.subtype],
        ['endDate', this.date],
        ['aggregation', this.currentAgg.value],
        ['chartConnectionCategories', this.currentMetric.type],
        ['mainConnectionCategory', this.currentMetric.type],
        ['showConfidenceIntervals', this.showConfidenceRate],
        ...this.displayOps.map((networkOp) => ['operators', networkOp.canonical_network_id]),
      ];

      const urlSearchParams = new URLSearchParams(queryParam);

      const url = `/focus/qoe/details/?${urlSearchParams.toString()}`;

      return url;
    },
    drillDownIntoFocus(locationKey) {
      const url = this.drillDownIntoFocusUrl(locationKey);
      if (this.user.dashboards.includes('focus')) {
        window.open(url, '_blank');
      } else {
        this.isOpenFocusPromotionDialog = true;
      }
    },
    hasDrilldownToFocus(locationKey) {
      return !!this.drillDownIntoFocusUrl(locationKey);
    },
    handlePromotionDialogUpdate(newValue) {
      this.isOpenFocusPromotionDialog = newValue;
    },
    minAndMax(list) {
      return metricMinAndMax(list, this.currentMetric);
    },
    onTabChange(tab, granularityId) {
      this.sortBy = null;
      this.sortOrder = -1;

      this.update({
        location: this.currentCountry.key,
        geocoding: granularityId,
      });
    },
    setInitialTimeframe() {
      const { end, start } = this.timeRange;
      const isBeforeDate = isBefore(getSafeDate(router.currentRoute.value.params.date), getSafeDate(start));
      if (isBeforeDate) {
        this.setTimeRange({
          start: new Date(router.currentRoute.value.params.date),
          end,
        });
      }
    },
    update({
      agg = this.agg,
      country = this.country,
      date = this.date,
      geocoding = this.geocoding,
      location = this.location,
      metric = this.metric,
      network = this.network,
    }) {
      this.$router.push({
        name: ROUTES.CompetitiveDetails,
        params: {
          location,
          metric,
          agg,
          date,
          country,
          geocoding,
          network,
        },
      });
    },
    navigateToPoint(date) {
      this.update({ date });
    },
    mapIsUnchanged(from, to) {
      return this.showRegionsAndCities && parseInt(from) < 4 && parseInt(to) < 4;
    },
    getRankLabel,
  },
};
</script>
<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;
@import 'scss/onx-breakpoints.module';

$map-height: 560px;

.onx-table {
  table-layout: auto;
}

.Details {
  .map-container {
    position: relative;

    .onx-metric-box {
      z-index: 1;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 360px;

      @include desktop {
        top: 16px;
        left: 16px;
      }

      .onx-metric-box__header {
        display: block;
        padding-top: 12px;
        padding-bottom: 8px;

        .onx-caption {
          font-weight: 400;
        }
      }
    }
  }

  .onx-metric-box.collapsed .onx-metric-box__header {
    height: 44px;
    padding-bottom: 0;
  }

  .onx-national-card__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  .onx-national-card__title {
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .ChartWrapperExtended {
    margin: 0 16px;
    padding: 16px 24px;

    &--extra-top-margin {
      margin: 32px 16px 0 16px;
    }
  }
}

.Details__sidebar {
  @include xy-grid(vertical, $wrap: false);
  height: calc(100vh - 2rem);

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-5gIcon {
    margin-right: 10px;
    width: 47px;
    height: 44px;
  }

  &-title {
    padding-bottom: 0 !important;
  }

  &-title-inner {
    display: flex;
    align-items: center;
    margin-left: -15px;
  }
}

@media screen and (max-width: $ci-breakpoint) {
  .Details__sidebar {
    height: initial;
  }
}

.Details__mapWrapper {
  min-height: $map-height;

  .ChartWrapper__placeholder {
    width: auto;
    height: $map-height;
  }
}

.Details__mapView {
  height: $map-height;
}

.Details__content {
  &__title-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    padding: 0 8px;
    height: 40px;
  }

  &__list-view {
    padding: 16px 0;
    overflow: auto;

    tbody tr td {
      &:last-child {
        width: 30px;
        text-align: right;
      }

      &:first-child,
      &:nth-child(2) {
        font-weight: 700;
        font-size: pxToRem(12);
      }
    }

    &__header--highlighted {
      border-bottom: 2px solid var(--teal-500);

      & i.onx-icon {
        visibility: visible;
      }
    }

    &__header__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__copyright {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 0 !important;
    }
  }
}
</style>
