import omit from 'lodash/omit';

import type {
  DatasetsAPIDataTransformerConstructor,
  DataTransformerFnOptions,
  DataTransformerReturnedItem,
} from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';
import type { DatasetsAPIDataStructuresEnum } from '@/types/MetricStructures';

// This is exported to be used in the ticks callback function for ecq_score_performance_driver and ccq_score_performance_driver,
// where the labels are split into arrays. ChartJS treats array labels as multi-line labels. This is done because labels don't fit on one line,
// and, by default, ChartJS rotates them when that happens.
export const ttfbMeetingThresholdLabel = 'Time to First Byte Meeting Threshold';
export const createThresholdConfigs = (cq: 'ecq' | 'ccq') => {
  const cqLabel = cq.toUpperCase();
  return {
    [`est_${cq}_download_met`]: {
      label: 'Download Meeting Threshold',
      tooltipLabel: `${cqLabel} if Download Threshold met`,
      color: '#003D40',
    },
    [`est_${cq}_upload_met`]: {
      label: 'Upload Meeting Threshold',
      tooltipLabel: `${cqLabel} if Upload Threshold met`,
      color: '#1C5356',
    },
    [`est_${cq}_latency_met`]: {
      label: 'Latency Meeting Threshold',
      tooltipLabel: `${cqLabel} if Latency Threshold met`,
      color: '#797D7F',
    },
    [`est_${cq}_icmp_latency_met`]: {
      label: 'ICMP Latency Meeting Threshold',
      tooltipLabel: `${cqLabel} if ICMP Latency Threshold met`,
      color: '#797D7F',
    },
    [`est_${cq}_jitter_met`]: {
      label: 'Jitter Meeting Threshold',
      tooltipLabel: `${cqLabel} if Jitter Threshold met`,
      color: '#797D7F',
    },
    [`est_${cq}_packet_loss_met`]: {
      label: 'Packet Discard Meeting Threshold',
      tooltipLabel: `${cqLabel} if Packet Discard Threshold met`,
      color: '#797D7F',
    },
    // Apparently this ttff is mislabeled, and is instead TTFB.
    // TTFF is not meant to be here, only TTFB.
    // These are duplicated because to hopefully prevent the chart breaking when est_ecq_ttff_met changes to est_ecq_ttfb_met.
    // Please remove `est_${cq}_ttff_met` when the change is made.
    [`est_${cq}_ttff_met`]: {
      label: ttfbMeetingThresholdLabel,
      tooltipLabel: `${cqLabel} if Time to First Byte Threshold met`,
      color: '#797D7F',
    },
    [`est_${cq}_ttfb_met`]: {
      label: ttfbMeetingThresholdLabel,
      tooltipLabel: `${cqLabel} if Time to First Byte Threshold met`,
      color: '#797D7F',
    },
  };
};

const thresholdLineColor = '#CC8816';

const transformScorePerformanceDrivers = (
  cq: 'ecq' | 'ccq',
): DatasetsAPIDataTransformerConstructor<
  DatasetsAPIDataStructuresEnum.EcqScorePerformanceDrivers | DatasetsAPIDataStructuresEnum.CcqScorePerformanceDrivers,
  Pick<DataTransformerFnOptions, 'mainOperator'>,
  { datasets: DataTransformerReturnedItem[]; labels: string[] }
> => {
  return ({ mainOperator }) => {
    return (response, horizontal?: boolean) => {
      const dataAxis = horizontal ? 'x' : 'y';
      const labelAxis = horizontal ? 'y' : 'x';

      if (!mainOperator) {
        return { datasets: [], labels: [] };
      }

      const datum = response.data.find((datum) => datum.canonical_network_id === mainOperator.canonical_network_id);

      if (!datum) {
        return { datasets: [], labels: [] };
      }

      const threshold = 'ecq' in datum ? datum.ecq : datum.ccq; // line value
      const values = omit(datum, ['canonical_network_id', 'location', 'date', cq]);
      const valueEntries = Object.entries(values);

      const thresholdConfigs = createThresholdConfigs(cq);
      const bars = valueEntries.map(([key, value]) => {
        const thresholdConfig = thresholdConfigs[key];

        return {
          type: 'bar',
          data: [
            {
              ...datum,
              operatorName: mainOperator.name_mapped,
              [dataAxis]: value,
              [labelAxis]: thresholdConfig.label,
            },
          ],
          backgroundColor: thresholdConfig.color,
          color: thresholdConfig.color,
          label: thresholdConfig.label,
          tooltipLabel: thresholdConfig.tooltipLabel,
          yAxisID: 'y',
          xAxisID: 'x',
          dataAxis,
          labelAxis,
          order: 1,
          meta: {
            imageExportLegend: {
              color: thresholdConfig.color,
              label: thresholdConfig.label,
              y: value,
            },
          },
        };
      });

      const thresholdLine = {
        type: 'line',
        data: bars.map(() => threshold),
        backgroundColor: thresholdLineColor,
        borderColor: thresholdLineColor,
        color: thresholdLineColor,
        label: `${cq.toUpperCase()} Score (current)`,
        xAxisID: 'xThresholdAxis',
        yAxisID: 'yThresholdAxis',
        dataAxis,
        labelAxis,
        pointRadius: 0,
        get meta() {
          return {
            imageExportLegend: {
              color: this.color,
              label: this.label,
              y: threshold,
            },
            operator: mainOperator,
          };
        },
      };

      const labels = bars.map((bar) => bar.label);

      return {
        datasets: [...bars, thresholdLine],
        labels,
      };
    };
  };
};

export default transformScorePerformanceDrivers;
