import transformSimpleStructureSubmetricsByOperator from '../data-transformers/transformSimpleStructureSubmetricsByOperator';
import simpleStructureSubmetricByOperatorTooltip from '../tooltips/simpleStructureSubmetricByOperatorTooltip';
import transformBandCountsPerOperator from '../data-transformers/transformBandCountsByOperator';
import transformConfidenceIntervalsByOperator from '../data-transformers/transformConfidenceIntervalsByOperator';
import { prepareSitemapperDetailsForTable } from '../data-transformers/prepareSitemapperDetailsForTable';
import { ChartMetricDefinition, InitialSortDirection } from '@/chart-metric-definitions/MetricDefinition';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import { METRIC_TYPES } from '@/constants/constants';
import exportDatasetToCsv from '@/utils/exportDatasetToCsv';
import { sitemapperDetailsColumns } from '@/360/base-station/table-columns/sitemapperDetailColumns';
import { exportSitemapperEstimatedLocationConfidenceToCsv } from '@/360/csv-export-overrides/exportSitemapperEstimatedLocationConfidenceToCsv';

/*
 * Most of these have hardcoded chart titles because the metric default title is ENodeB Count,
 * but the desired chart names are differnt, like "Cell Count by Band". Also ENodeB -> eNodeB
 */
const onx360BaseStationSiteMapperChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  withOperators: [
    {
      metricSubtype: MetricSubtypes.ENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        nbDays: 0,
        chartTitlePlaceholders: 'eNodeB Count by Operator', // hardcoded to remove the automatic chart connection category "Overall" prefix
      },
      chartContainerClass: 'onx-col-12 lg:onx-col-4 xxl:onx-col-4',
      get tooltipPlugin() {
        return simpleStructureSubmetricByOperatorTooltip({ horizontal: !!this.chartAttrs?.horizontal });
      },
      transformData: transformSimpleStructureSubmetricsByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Operator', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [dataset.label, datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
    },
    {
      chartType: ChartTypesEnum.DatasetTable,
      dataset: 'onx360_sitemapper_enodeb_table',
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        chartTitlePlaceholders: 'Estimated Location Confidence',
      },
      transformData: prepareSitemapperDetailsForTable,
      onExportCsv: exportSitemapperEstimatedLocationConfidenceToCsv,
      chartContainerClass: 'onx-col-12 lg:onx-col-8',
      columns: sitemapperDetailsColumns,
      initialSortColumnKey: 'cell_quantifiedestimatedlocationconfidence',
      initialSortDirection: InitialSortDirection.Desc,
    },
    {
      metricSubtype: MetricSubtypes.FirstRecordDateAvgCellSiteCount,
      chartType: ChartTypesEnum.Trend,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        chartTitlePlaceholders: 'Cell Site Counts Over Time', // hardcoded because otherwise it says "Overall ENodeB Count",
      },
      chartContainerClass: 'onx-col-12 lg:onx-col-4',
    },
  ],
  byOperator: [
    {
      metricSubtype: MetricSubtypes.BandENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: true,
        chartTooltipShowSecondaryValue: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Count by Band for :operator', // instead of "Overall ENodeB Count"
        dynamicHeight: true,
      },
      chartContainerClass: 'onx-grid-item__full-width',
      transformData: transformBandCountsPerOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Band', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
    },
    {
      metricSubtype: MetricSubtypes.LocationConfidenceENodeBCount,
      chartType: ChartTypesEnum.BarChart,
      connectionCategory: METRIC_TYPES.Overall,
      chartAttrs: {
        horizontal: false,
        chartTooltipShowSecondaryValue: true,
        nbDays: 0,
        chartTitlePlaceholders: 'Cell Location Confidence Level for :operator',
      },
      transformData: transformConfidenceIntervalsByOperator,
      onExportCsv: exportDatasetToCsv({
        headers: ['Confidence', 'Count', 'Date'],
        getRow: (datum, dataset, date) => {
          return [datum[dataset.labelAxis], datum[dataset.dataAxis].toFixed(2), date];
        },
      }),
    },
  ],
};

export default onx360BaseStationSiteMapperChartMetricDefinitions;
