import get from 'lodash/get';

const isFinite = Number.isFinite;

const compareOperatorsByRankAndValue = (a, b) => {
  if (!isFinite(b.rank)) {
    return -1;
  } else if (!isFinite(a.rank)) {
    return 1;
  } else if (a.rank < b.rank) {
    return -1;
  } else if (a.rank > b.rank) {
    return 1;
  } else {
    return 0;
  }
};

export const sortAscendent = (a, b) =>
  Number.isFinite(a) && Number.isFinite(b) ? parseFloat(a) - parseFloat(b) : Number.isFinite(a) ? -1 : 1;
export const sortDescendent = (a, b) =>
  Number.isFinite(a) && Number.isFinite(b) ? parseFloat(b) - parseFloat(a) : Number.isFinite(a) ? -1 : 1;

const sortByMetricMean = (data, biggerIsBetter, field = 'mean') => {
  return data.sort((a, b) => {
    const aValue = get(a, field);
    const bValue = get(b, field);

    if (biggerIsBetter) {
      return sortDescendent(aValue, bValue);
    } else {
      return sortAscendent(aValue, bValue);
    }
  });
};

export const sortByMetricMeanAndRank = (data, biggerIsBetter, field = 'mean') => {
  return sortByMetricMean(
    data.sort((a, b) => sortAscendent(a.rank, b.rank)),
    biggerIsBetter,
    field,
  );
};

const sliceByRange = (data, range) => {
  const dataToReverse = [...data];
  dataToReverse.reverse();
  const percent = dataToReverse.length / 100;
  const firstIndex = Math.ceil(parseInt(range.split('-')[0], 10) * percent);
  const lastIndex = Math.ceil(parseInt(range.split('-')[1], 10) * percent) + 1;
  return dataToReverse.slice(firstIndex, lastIndex).reverse();
};

const parseRangeString = (rangeString) => {
  const rangeValues = (rangeString || '').split('-');
  const left = parseInt(rangeValues[0]);
  const right = parseInt(rangeValues[1]);
  const validValues = isFinite(left) && isFinite(right);
  return {
    left: validValues ? left : null,
    right: validValues ? right : null,
  };
};

export function dataValues(data, key) {
  return data.filter((x) => Number.isFinite(x[key])).map((x) => Number(x[key]));
}

export function sortValues(data, descending) {
  return [...data].sort((a, b) => (descending ? b - a : a - b));
}

export function get5stepsPercentiles(array, key = 'mean') {
  if (array.length === 0) return [0, 0, 0, 0, 0, 0];

  const valuesList = sortValues(dataValues(array, key), false);
  const percentileList = [0, 20, 40, 60, 80, 100];

  return percentileList.map((percentile) => {
    if (percentile === 0) return valuesList[0];
    if (percentile === 100) return valuesList[valuesList.length - 1];

    const index = (valuesList.length - 1) * (percentile / 100);
    const lower = Math.floor(index);
    const upper = lower + 1;
    const weight = index % 1;

    if (upper >= valuesList.length) return valuesList[lower];

    return Math.round((valuesList[lower] * (1 - weight) + valuesList[upper] * weight) * 100) / 100;
  });
}

const isRankComparisonNegative = (rankDifference) => {
  return isFinite(rankDifference) ? rankDifference > 0 : undefined;
};

const isRankComparisonNeutral = (rankDifference) => {
  return isFinite(rankDifference) ? rankDifference === 0 : undefined;
};

const isRankComparisonPositive = (rankDifference) => {
  return isFinite(rankDifference) ? rankDifference < 0 : undefined;
};

const isValueComparisonNegative = (valueDifference) => {
  return isFinite(valueDifference) ? valueDifference < 0 : undefined;
};

const isValueComparisonPositive = (valueDifference) => {
  return isFinite(valueDifference) ? valueDifference > 0 : undefined;
};

const filterHiddenData = (array, hiddenNetworksIds) => {
  if (hiddenNetworksIds.length === 0) {
    return array;
  }
  return array.filter((scan) => !hiddenNetworksIds.includes(scan.canonical_network_id));
};
export {
  compareOperatorsByRankAndValue,
  isRankComparisonNegative,
  isRankComparisonNeutral,
  isRankComparisonPositive,
  isValueComparisonNegative,
  isValueComparisonPositive,
  parseRangeString,
  sliceByRange,
  sortByMetricMean,
  filterHiddenData,
};
