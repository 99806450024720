import Chart, { TooltipModel } from 'chart.js/auto';
import get from 'lodash/get';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { ChartMetricDefinition, LoopOverOptions } from '@/chart-metric-definitions/MetricDefinition';

import { getTooltipState } from '@/utils/charts';

import transformHourlyMetricForEachOperator from '@/chart-metric-definitions/data-transformers/transformHourlyMetricForEachOperator';
import cdnBarSeriesTransform from '@/components/onx/charts/OnxCdnBarChart/cdnBarSeriesTransform';
import transformScorePerformanceDrivers, {
  ttfbMeetingThresholdLabel,
} from '@/chart-metric-definitions/data-transformers/transformScorePerformanceDrivers';
import { exportToCsv } from '@/utils/files';
import scorePerformanceDriversTooltip from '@/chart-metric-definitions/tooltips/scorePerformanceDriversTooltip';

const hourlyTrendChartConfig = {
  chartAttrs: {
    ignoreDate: true,
    nbDays: 0,
    chartConfig: {
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              minUnit: 'hour',
              unit: 'hour',
              displayFormats: {
                hour: 'HH',
              },
            },
            ticks: {
              source: 'date',
              stepSize: 1,
              autoSkip: false,
            },
          },
        },
      },
    },
  },
  transformData: transformHourlyMetricForEachOperator,
  tooltipPlugin: (context: { chart: Chart; tooltip: TooltipModel<'bar'> }, tooltip: any) => {
    const tooltipModel = context.tooltip;
    const title = get(tooltipModel, ['dataPoints', 0, 'raw', 'x'], '');
    const tooltipVisible = tooltipModel.dataPoints.length > 0;

    const datapoints = tooltipModel.dataPoints
      .filter((datapoint) => {
        const filterOut = get(datapoint, ['dataset', 'filterOut'], false);
        return !filterOut;
      })
      .map((datum) => {
        const raw = datum.raw as { x: string; y: number; lci: number; uci: number };

        return {
          label: datum.dataset.label,
          value: raw.y,
          color: datum.dataset.borderColor,
          lci: raw.lci,
          uci: raw.uci,
        };
      });

    if (tooltipVisible) {
      tooltip.date = title;
      tooltip.datapoints = datapoints;

      const tooltipState: { posClass: string; tooltipStyle: object; display: boolean } = getTooltipState(
        context.chart,
        tooltipModel,
      );

      tooltip.posClass = tooltipState.posClass;
      tooltip.tooltipStyle = tooltipState.tooltipStyle;
      tooltip.display = tooltipState.display;
    }
  },
};

const qoeDetailsChartMetricDefinitions: Record<string, ChartMetricDefinition[]> = {
  download: [
    {
      metricSubtype: MetricSubtypes.BinnedDownload,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.Download,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Download Speed Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnDownload,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.Download,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Download Speed (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnDownload,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.Download,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Download Speed (Mbps) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.PeakDownload,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.PeakDownload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Download Peak Speed (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ConsistencyDownload,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ConsistencyDownload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Download Speed Consistency (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyDownload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.Download,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Hourly Download Speed (Mbps) Trend',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  upload: [
    {
      metricSubtype: MetricSubtypes.BinnedUpload,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.Upload,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Upload Speed Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnUpload,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.Upload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Upload Speed (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnUpload,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.Upload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Upload Speed (Mbps) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.PeakUpload,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.PeakUpload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Upload Peak Speed (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ConsistencyUpload,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ConsistencyUpload,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Upload Speed Consistency (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyUpload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.Upload,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Hourly Upload Speed (Mbps) Trend',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  gamesexperience: [
    {
      metricSubtype: MetricSubtypes.GamesJitter,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.GamesJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Jitter (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedGamesJitter,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.GamesJitter,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Games Jitter Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpGamesJitter,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.GamesJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Jitter (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.GamesPacketDelay,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.GamesPacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Delay (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedGamesPacketDelay,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.GamesPacketDelay,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Games Packet Delay Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpGamesPacketDelay,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.GamesPacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Delay (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpGamesPacketDelay,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.GamesPacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Delay (ms) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.GamesPacketLoss,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.GamesPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Loss (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedGamesPacketLoss,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.GamesPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Games Packet Loss Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpGamesPacketLoss,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.GamesPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Loss (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpGamesPacketLoss,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.GamesPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Games Packet Loss (%) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  ecq: [
    {
      metricSubtype: MetricSubtypes.EcqDownloadThroughput,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Download Throughput (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqDownloadThroughput,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType ECQ Download Throughput Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqDownloadThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Download Throughput (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdDownloadThroughput,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Download Throughput Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqUploadThroughput,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Upload Throughput (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqUploadThroughput,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType ECQ Upload Throughput Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqUploadThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Upload Throughput (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdUploadThroughput,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Upload Throughput Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqIcmpLatency,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ ICMP Latency (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqIcmpLatency,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType ECQ ICMP Latency Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqIcmpLatency,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ ICMP Latency (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdIcmpLatency,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ICMP Latency Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqJitter,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Jitter (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqJitter,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Jitter Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqJitter,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Jitter (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdJitter,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Jitter Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqPacketLoss,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Packet Loss (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqPacketLoss,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType ECQ Packet Loss Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqPacketLoss,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Packet Loss (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdPacketLoss,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Packet Loss Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqLatency,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Latency (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqLatency,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Latency Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqLatency,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Latency (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdLatency,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Latency Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Time to First Byte (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedEcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType ECQ Time to First Byte Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnEcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Time to First Byte (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqThresholdDownloadTimeFirstByte,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Time to First Byte Tests not meeting ECQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.EcqDownloadThroughputTestFailure,
      chartType: ChartTypesEnum.FailureStackedBar,
      chartGroup: MetricSubtypes.EcqDownloadThroughputTestFailure,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Download Throughput Test Failures (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.EcqServerResponseTestFailure,
      chartType: ChartTypesEnum.FailureStackedBar,
      chartGroup: MetricSubtypes.EcqServerResponseTestFailure,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ECQ Server Response Test Failures (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqDownload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqDownloadThroughput,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Download Throughput tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqUpload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqUploadThroughput,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Upload Throughput tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqIcmpLatency,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqIcmpLatency,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly ICMP Latency tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqJitter,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqJitter,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Hourly Jitter tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqPacketLoss,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqPacketLoss,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Packet Loss tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqLatency,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqLatency,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Latency tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyEcqTimeToFirstByte,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.EcqDownloadTimeFirstByte,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Time To First Byte tests not meeting ECQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  ccq: [
    {
      metricSubtype: MetricSubtypes.CcqDownloadThroughput,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Download Throughput (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqDownloadThroughput,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType CCQ Download Throughput Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqDownloadThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Download Throughput (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdDownloadThroughput,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Download Throughput Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqUploadThroughput,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Upload Throughput (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqUploadThroughput,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType CCQ Upload Throughput Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqUploadThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Upload Throughput (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdUploadThroughput,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Upload Throughput Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqIcmpLatency,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ ICMP Latency (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqIcmpLatency,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType CCQ ICMP Latency Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqIcmpLatency,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ ICMP Latency (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdIcmpLatency,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType ICMP Latency Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqJitter,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Jitter (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqJitter,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Jitter Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqJitter,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Jitter (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdJitter,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqJitter,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Jitter Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqPacketLoss,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Packet Loss (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqPacketLoss,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType CCQ Packet Loss Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqPacketLoss,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Packet Loss (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdPacketLoss,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqPacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Packet Loss Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqLatency,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Latency (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqLatency,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Latency Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqLatency,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Latency (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdLatency,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqLatency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Latency Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Time to First Byte (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType CCQ Time to First Byte Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnCcqDownloadTimeFirstByte,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Time to First Byte (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqThresholdDownloadTimeFirstByte,
      chartType: ChartTypesEnum.ThresholdTrend,
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Time to First Byte Tests not meeting CCQ Threshold (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.CcqDownloadThroughputTestFailure,
      chartType: ChartTypesEnum.FailureStackedBar,
      chartGroup: MetricSubtypes.CcqDownloadThroughputTestFailure,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Download Throughput Test Failures (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CcqServerResponseTestFailure,
      chartType: ChartTypesEnum.FailureStackedBar,
      chartGroup: MetricSubtypes.CcqServerResponseTestFailure,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType CCQ Server Response Test Failures (%)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqDownload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Download Throughput tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqUpload,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqUploadThroughput,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Upload Throughput tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqIcmpLatency,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqIcmpLatency,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly ICMP Latency tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqJitter,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqJitter,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Hourly Jitter tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqPacketLoss,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqPacketLoss,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Packet Loss tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqLatency,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqLatency,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Latency tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.HourlyCcqTimeToFirstByte,
      chartType: ChartTypesEnum.HourlyTrend,
      chartGroup: MetricSubtypes.CcqDownloadTimeFirstByte,
      ...hourlyTrendChartConfig,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Hourly Time To First Byte tests not meeting CCQ Threshold (%)',
        ...hourlyTrendChartConfig.chartAttrs,
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  videoexperience: [
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrExperience,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VideoExperience,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Experience (0 - 100) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnVideoAbrExperience,
      chartType: ChartTypesEnum.CdnBinned,
      chartGroup: MetricSubtypes.VideoExperience,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Video Experience for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VideoAbrTtff,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VideoAbrTtff,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Time to First Frame (s) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrTtff,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VideoAbrTtff,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Time to First Frame (s) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnVideoAbrTtff,
      chartType: ChartTypesEnum.CdnBinned,
      chartGroup: MetricSubtypes.VideoAbrTtff,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Video Time to First Frame for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VideoAbrStallingOccurrence,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VideoAbrStallingOccurrence,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Occurrence (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrStallingOccurrence,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VideoAbrStallingOccurrence,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Occurrence (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VideoAbrStallingTime,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VideoAbrStallingTime,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Time (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrStallingTime,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VideoAbrStallingTime,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Stalling Time (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnVideoAbrStallingTime,
      chartType: ChartTypesEnum.CdnBinned,
      chartGroup: MetricSubtypes.VideoAbrStallingTime,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Video Stalling Time for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VideoAbrThroughput,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VideoAbrThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Throughput Rate (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnVideoAbrThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VideoAbrThroughput,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Throughput Rate (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnVideoAbrThroughput,
      chartType: ChartTypesEnum.CdnBinned,
      chartGroup: MetricSubtypes.VideoAbrThroughput,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Video Throughput Rate for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VideoAbrResTime,
      chartType: ChartTypesEnum.VideoResStackedBar,
      chartGroup: MetricSubtypes.VideoAbrResTime,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Video Time On Resolution (%)',
        chartTitleTooltip:
          'High corresponds to the highest two resolutions i.e. 1440p and 2160p. Low corresponds to the lowest two resolutions i.e. 144p and 240p.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  voiceexperience: [
    {
      metricSubtype: MetricSubtypes.BinnedVoiceExperience,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.VoiceExperience,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Voice App Experience Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoiceExperience,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VoiceExperience,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice App Experience (0 - 100) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoiceExperience,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.VoiceExperience,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice App Experience (0 - 100) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.VoicePacketDelay,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VoicePacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Delay (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedVoicePacketDelay,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.VoicePacketDelay,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Voice Packet Delay Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoicePacketDelay,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VoicePacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Delay (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoicePacketDelay,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.VoicePacketDelay,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Delay (ms) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.VoicePacketLoss,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.VoicePacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Loss (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedVoicePacketLoss,
      chartType: ChartTypesEnum.Distribution,
      chartGroup: MetricSubtypes.VoicePacketLoss,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Voice Packet Loss Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoicePacketLoss,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartGroup: MetricSubtypes.VoicePacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Loss (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CpVoicePacketLoss,
      chartType: ChartTypesEnum.CdnTrend,
      chartGroup: MetricSubtypes.VoicePacketLoss,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Voice Packet Loss (%) Trend for :cdn',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  videoliveexperience: [
    {
      metricSubtype: MetricSubtypes.LiveVideoOffset,
      chartGroup: MetricSubtypes.LiveVideoOffset,
      chartType: ChartTypesEnum.Trend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Average Offset (s) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoOffset,
      chartGroup: MetricSubtypes.LiveVideoOffset,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Average Offset (s) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnLiveVideoOffset,
      chartGroup: MetricSubtypes.LiveVideoOffset,
      chartType: ChartTypesEnum.CdnBinned,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Live Video Average Offset for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.LiveVideoStallingTime,
      chartGroup: MetricSubtypes.LiveVideoStallingTime,
      chartType: ChartTypesEnum.Trend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Time (ms) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoStallingTime,
      chartGroup: MetricSubtypes.LiveVideoStallingTime,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Time (ms) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnLiveVideoStallingTime,
      chartGroup: MetricSubtypes.LiveVideoStallingTime,
      chartType: ChartTypesEnum.CdnBinned,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Live Video Stalling Time for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.LiveVideoStallingOccurrence,
      chartGroup: MetricSubtypes.LiveVideoStallingOccurrence,
      chartType: ChartTypesEnum.Trend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Occurrence (%) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoStallingOccurrence,
      chartGroup: MetricSubtypes.LiveVideoStallingOccurrence,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Stalling Occurrence (%) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.LiveVideoTtff,
      chartGroup: MetricSubtypes.LiveVideoTtff,
      chartType: ChartTypesEnum.Trend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Time to First Frame (s) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoTtff,
      chartGroup: MetricSubtypes.LiveVideoTtff,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Time to First Frame (s) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnLiveVideoTtff,
      chartGroup: MetricSubtypes.LiveVideoTtff,
      chartType: ChartTypesEnum.CdnBinned,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Live Video Time to First Frame for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.LiveVideoThroughput,
      chartGroup: MetricSubtypes.LiveVideoThroughput,
      chartType: ChartTypesEnum.Trend,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Throughput Rate (Mbps) Trend',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.CdnLiveVideoThroughput,
      chartGroup: MetricSubtypes.LiveVideoThroughput,
      chartType: ChartTypesEnum.CdnBar,
      transformData: cdnBarSeriesTransform,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Throughput Rate (Mbps) by CDN',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.BinnedCdnLiveVideoThroughput,
      chartGroup: MetricSubtypes.LiveVideoThroughput,
      chartType: ChartTypesEnum.CdnBinned,
      chartAttrs: {
        chartTitlePlaceholders:
          ':connectionCategory :deploymentType Live Video Throughput Rate for :cdn Distribution (Proportion of Device %)',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },

    {
      metricSubtype: MetricSubtypes.LiveVideoResTime,
      chartGroup: MetricSubtypes.LiveVideoResTime,
      chartType: ChartTypesEnum.VideoResStackedBar,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Live Video Time On Resolution (%)',
        chartTitleTooltip:
          'High corresponds to the highest two resolutions i.e. 1440p and 2160p. Low corresponds to the lowest two resolutions i.e. 144p and 240p.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
  sessionreliability: [
    {
      metricSubtype: MetricSubtypes.ReliabilityCompletion,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ReliabilityCompletion,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Completion (%) Trend',
        chartTitleTooltip: 'Whether tasks initiated by the user’s device are completed.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ReliabilityConnectivity,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ReliabilityConnectivity,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Connectivity (%) Trend',
        chartTitleTooltip:
          'The proportion of time when the network is available and the device can connect to the internet.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ReliabilitySufficiency,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ReliabilitySufficiency,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Sufficiency (%) Trend',
        chartTitleTooltip: 'The probability that tasks will be executed sufficiently well for the user.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ReliabilityConnectionSuccess,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ReliabilityConnectionSuccess,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Connection Success (%) Trend',
        chartTitleTooltip:
          'A derived indicative component calculated as Signal Availability x Connectivity, where Signal Availability is the proportion of time users can successfully connect to a mobile network. It represents the ability of users to make a successful connection. It is not used directly in the calculation of the Reliability metric.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
    {
      metricSubtype: MetricSubtypes.ReliabilitySessionRelability,
      chartType: ChartTypesEnum.Trend,
      chartGroup: MetricSubtypes.ReliabilitySessionRelability,
      chartAttrs: {
        chartTitlePlaceholders: ':connectionCategory :deploymentType Session Completion (%) Trend',
        chartTitleTooltip:
          'A derived component calculated as Completion x Sufficiency. It represents the ability of users to complete tasks on their networks.',
      },
      loopOver: [LoopOverOptions.ConnectionCategories],
    },
  ],
};

const scorePerformanceDriverChartConfig = {
  chartType: ChartTypesEnum.ScorePerformanceDriver,
  chartAttrs: {
    horizontal: false,
    max: 100,
    chartConfig: {
      options: {
        scales: {
          x: {
            ticks: {
              // This splits the labels into multiple lines so ChartJS doesn't end up rotating them to fit, because they're too long to fit horizontally on one line
              // TTFB gets special treatment because it's the longest in terms of words, and this leads to the label being of similar height to the others
              callback(labelIndex: number) {
                const label = (this as any).getLabelForValue(labelIndex);

                if (label === ttfbMeetingThresholdLabel) {
                  return ['Time to', 'First Byte', 'Meeting', 'Threshold'];
                }

                return label.split(' ');
              },
              font: (context: any) => {
                const width = context.chart.width;
                const referenceWidth = 700;
                const baseFontSize = Chart.defaults.font.size || 12;

                const size = Math.min(baseFontSize, Math.floor((width / referenceWidth) * baseFontSize));

                return {
                  size,
                };
              },
            },
          },
          xThresholdAxis: {
            display: false,
            offset: false,
          },
          yThresholdAxis: {
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            offset: false,
            beingAtZero: true,
            max: 100,
          },
        },
      },
    },
  },
  get tooltipPlugin() {
    return scorePerformanceDriversTooltip({ horizontal: !!this.chartAttrs?.horizontal });
  },
  onExportCsv: (data: { datasets: any[] }, title: string) => {
    const date = data.datasets[0].data[0].date.split('T')[0];
    const rows = data.datasets.flatMap((dataset: any) => {
      if (dataset.type === 'line') {
        const datum = dataset.data[0] as number;
        return [[dataset.label, datum, dataset.meta.operator.name_mapped, date]];
      }

      return dataset.data.map((datum: any) => {
        return [dataset.label, datum[dataset.dataAxis], datum.operatorName, date];
      });
    });

    exportToCsv(`${title}.csv`, [['Metric', 'Threshold', 'Operator', 'Date'], ...rows]);
  },
};

export const qoeDetailsScorePerformanceDriversChartMetricDefinitions = {
  ecq: [
    {
      dataset: 'ecq_score_performance_driver',
      ...scorePerformanceDriverChartConfig,
      chartAttrs: {
        ...scorePerformanceDriverChartConfig.chartAttrs,
        chartTitlePlaceholders: 'ECQ Score if Thresholds Reached (Estimated) for :operator',
      },
      transformData: transformScorePerformanceDrivers('ecq'),
      loopOver: [LoopOverOptions.Operators],
    },
  ],
  ccq: [
    {
      dataset: 'ccq_score_performance_driver',
      chartGroup: MetricSubtypes.CcqDownloadThroughput,
      ...scorePerformanceDriverChartConfig,
      chartAttrs: {
        ...scorePerformanceDriverChartConfig.chartAttrs,
        chartTitlePlaceholders: 'CCQ Score if Thresholds Reached (Estimated) for :operator',
      },
      transformData: transformScorePerformanceDrivers('ccq'),
      loopOver: [LoopOverOptions.Operators],
    },
  ],
};

export default qoeDetailsChartMetricDefinitions;
