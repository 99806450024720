<script setup>
import { computed } from 'vue';
import InfoIcon from '@/components/onx/icons/InfoIcon';
import OnxDelta from '@/components/onx/OnxDelta';
import OperatorAvatar from '@/components/OperatorAvatar';
import CustomTooltip from '@/components/tooltip/CustomTooltip';
import { COMPARE_TO } from '@/constants/constants';
import router from '@/router';

defineProps({
  ranks: {
    type: Array,
    default: () => [],
  },
});

const tooltipMessage = computed(() => {
  const compareToParam = router.currentRoute.value.params?.compareTo || '30days';
  const compareTo = COMPARE_TO[compareToParam]?.label;

  return `
      <p>Score & Rank Change aims at showing changes in scores or ranks of experiential metrics. There are three components:</p>
      <ul class="list-styled">
        <li>Rank change: Change in individual network rank compared to ${compareTo}.</li>
        <li>Score change: Change in individual network scores compared to ${compareTo}.</li>
        <li>Score change vs Others: The difference between your score change compared to the change of your competing operators. By definition, your network will not show any change. For each competing operator the difference is calculated as follows: Your Score Change MINUS Operator B score change.</li>
      </ul>
    `;
});
</script>

<template>
  <div class="ScoreChangeTable">
    <template v-for="operator in ranks" :key="operator.operatorName">
      <OperatorAvatar
        :background-color="operator.operatorColor"
        :name="operator.operatorName"
        :background-style="operator.operatorIsMvno ? 'outline' : 'fill'"
      />

      <div class="ScoreChangeTable__value">
        {{ operator.value }}
      </div>

      <OnxDelta v-for="(score, index) in operator.scoreChange" :key="index" :value="score" />
    </template>

    <div>
      <CustomTooltip :message="tooltipMessage">
        <InfoIcon />
      </CustomTooltip>
    </div>
    <div />
    <div><small>Rank change</small></div>
    <div><small>Change</small></div>
    <div><small>vs. Others</small></div>
  </div>
</template>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$row-height: 28px;

.ScoreChangeTable {
  display: grid;
  grid-template-columns: [operator] 28px [value] 1fr [rank_change] 1fr [change] 1fr [vs_others] 1fr;
  grid-template-rows: auto;
  grid-auto-rows: $row-height;
  grid-gap: 16px;
  row-gap: 20px;
  align-items: center;

  width: 100%;
  padding: 0 16px 0 8px;

  small {
    font-size: $font-size-10;
    color: var(--charcoal-200);
  }
}

.ScoreChangeTable__value {
  font-size: $font-size-14;
}
</style>
