<script setup lang="ts">
import { type AxiosRequestConfig } from 'axios';
import { computed, ref, toRefs, unref } from 'vue';

import useTableColumnSorts from './useTableColumnSorts';
import type { DataTransformerMetricsAPIResponseCallback } from '@/chart-metric-definitions/data-transformers/DataTransformerFnType';

import osApi from '@/api/osApi';
import CsvExport from '@/components/CsvExport.vue';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import { Column } from '@/components/onx/table';
import OnxRawTable from '@/components/onx/table/OnxRawTable.vue';
import Paginator from '@/components/visual/Paginator.vue';
import { usePaginatedDataset } from '@/composables/usePaginatedDataset';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import { exportToCsv } from '@/utils/files';
import { formatNumber } from '@/utils/format';

interface Props {
  dashboard: Dashboards;
  location: string | number;
  metric: string;
  enabled: boolean;
  connectionCategories?: string[];

  onExportToCsv?: (rows: any[][], filename: string, params?: any) => void | Promise<void>;
  transform?: DataTransformerMetricsAPIResponseCallback<any>;

  /* Table Options */
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: 'asc' | 'desc';

  /** Override the chart metric title */
  tableTitle: string;
  tableSubtitle?: string;
  exportTitle?: string;
  exportSubtitle?: string;

  tableTooltip?: string;
  otherRequestParams?: AxiosRequestConfig['params'];
}

const props = defineProps<Props>();
const { columns, enabled, location, metric, tableTitle, tableTooltip } = toRefs(props);
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.ThreeSixty);

const { onClickColumn, sortColumnKey, sortDirection } = useTableColumnSorts(columns, {
  initialSortColumnKey: props.initialSortColumnKey,
  initialSortDirection: props.initialSortDirection,
});

const {
  gotoPage,
  page,
  query: { data: response, isLoading, isPending, isRefetching },
  queryParams: datasetQueryParams,
  totalCount,
  url: datasetURL,
} = usePaginatedDataset(props.dashboard, metric, location, {
  sortColumnKey,
  sortDirection,
  enabled,
  canonicalNetworkIDs: computed(() => selectedOperators.value.map((operator) => operator.canonical_network_id)),
  connectionCategories: computed(() => props.connectionCategories || undefined),
  requestParams: props.otherRequestParams,
});

const totalCountWithCommas = computed(() => {
  return formatNumber(totalCount.value);
});

const rows = computed(() => {
  const responseData = response.value?.data;
  if (!responseData) {
    return [];
  }

  if (props.transform) {
    return props.transform(responseData.data as any);
  } else {
    return responseData.data;
  }
});

const maxPage = computed(() => {
  if (totalCount.value > 0) {
    return Math.ceil(totalCount.value / 20);
  } else {
    return 1;
  }
});

const isDownloadingExport = ref(false);
const canDownload = computed(() => {
  return !isLoading.value && !isDownloadingExport.value && rows.value.length > 0;
});
const onExportToCsv = async () => {
  if (!canDownload.value) {
    return;
  }

  isDownloadingExport.value = true;
  let response;
  try {
    response = await osApi.get(datasetURL.value, {
      params: {
        ...datasetQueryParams.value,
        page: 1,
        page_size: 20000, // file export limit
      },
    });
  } finally {
    isDownloadingExport.value = false;
  }

  if (response.status !== 200 || !response.data.data) {
    return;
  }

  const exportRowsAsObjects: any[] = props.transform ? props.transform(response.data.data as any) : response.data.data;
  const exportRows = exportRowsAsObjects.map((row) => {
    return unref(columns).map((column) => {
      if (column.cell) {
        return column.cell(row);
      } else {
        return column.value(row);
      }
    });
  });
  const columnHeaders = unref(columns).map((column) => column.header);
  exportToCsv(`${tableTitle.value}.csv`, [columnHeaders, ...exportRows]);
};
</script>

<template>
  <OnxChartContainer
    :title="tableTitle"
    :subtitle="tableSubtitle"
    :chart-title-tooltip="tableTooltip"
    :screenshot-title="exportTitle"
    :screenshot-subtitle="exportSubtitle"
    :enable-csv-export="false"
    :enable-image-export="false"
    :loading="isLoading || isRefetching || isPending"
    :no-data="rows.length === 0"
    data-test-id="onx-table__root"
  >
    <div class="onx-table-wrapper">
      <OnxRawTable
        :rows="rows"
        :columns="columns"
        :sort-column-key="sortColumnKey"
        :sort-direction="sortDirection"
        @column:click="onClickColumn"
      />
    </div>
    <template #tools>
      <div class="onx-dataset-item-count">{{ totalCount === 1 ? '1 item' : `${totalCountWithCommas} items` }}</div>
      <div class="onx-dataset-paginator-container">
        <Paginator
          :goto-page="gotoPage"
          :page="page || 1"
          :max-page="maxPage"
          :disabled="isLoading"
          :total-count="totalCount"
        />
      </div>

      <CsvExport :disabled="!canDownload" @click.stop="onExportToCsv">
        <DownloadFileIcon :loading="isDownloadingExport" />
      </CsvExport>
    </template>
  </OnxChartContainer>
</template>

<style lang="scss">
.onx-table-wrapper {
  overflow-x: auto;
  width: 100%;
  max-height: 310px;
  overflow-y: scroll;
}

.onx-dataset-item-count {
  font-size: 0.75rem;
  color: var(--onx-btn-primary-disabled-text-color);
}

.onx-dataset-paginator-container {
  flex-grow: 1;
  flex-basis: 0;
}

.onx-table__export {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 8px;
}
</style>
