import { Column } from '@/components/onx/table';
import { CongestionAllCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionAllCellsForTable';
import { toFixedNoZeroPad } from '@/utils/helpers';
import { ascNumberSort } from '@/utils/sorting';

export const congestionAllCellsColumns: Column<CongestionAllCellsRow>[] = [
  {
    value: (row) => row.operatorName,
    header: 'Operator',
    key: 'operator',
    filterable: true,
  },
  {
    value: (row) => row.enodebid,
    header: 'eNodeB ID',
    key: 'connection_enodebid',
  },
  {
    value: (row) => row.cellId,
    header: 'Cell ID',
    key: 'connection_cid',
  },
  {
    value: (row) => row.connectionCategory,
    header: 'Connection Category',
    key: 'connection_category',
  },
  {
    value: (row) => row.congestionScore,
    cell: (row) => (row.congestionScore != null ? Math.round(row.congestionScore) : ''),
    header: 'Congestion Score',
    key: 'congestion_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downlinkScore,
    cell: (row) => (row.downlinkScore != null ? Math.round(row.downlinkScore) : ''),
    header: 'Downlink Score',
    key: 'downlink_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.uplinkScore,
    cell: (row) => (row.uplinkScore != null ? Math.round(row.uplinkScore) : ''),
    header: 'Uplink Score',
    key: 'uplink_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downloadUploadDelta,
    cell: (row) => (row.downloadUploadDelta != null ? Math.round(row.downloadUploadDelta) : ''),
    header: 'DL-UL Delta',
    key: 'dl_ul_delta',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downloadThroughput,
    cell: (row) => (row.downloadThroughput != null ? toFixedNoZeroPad(row.downloadThroughput, 1) : ''),
    header: 'Download Throughput (Mbps)',
    key: 'download_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.uploadThroughput,
    cell: (row) => (row.uploadThroughput != null ? toFixedNoZeroPad(row.uploadThroughput, 1) : ''),
    header: 'Upload Throughput (Mbps)',
    key: 'upload_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.signalStrength,
    cell: (row) => (row.signalStrength != null ? Math.round(row.signalStrength) : ''),
    header: 'Signal Strength (dBm)',
    key: 'signal_strength',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.packetLossAndDiscard,
    cell: (row) => (row.packetLossAndDiscard != null ? toFixedNoZeroPad(row.packetLossAndDiscard, 1) : ''),
    header: 'Packet Loss & Discard (%)',
    key: 'packet_loss_and_discard',
    sort: ascNumberSort,
  },
];
