<script setup lang="ts">
import { AxiosRequestConfig } from 'axios';
import type { ChartDataset } from 'chart.js';
import { ComponentPublicInstance, computed, ref, toRefs } from 'vue';
import { OnxChartBaseProps } from '@/components/onx/charts/OnxChartBaseProps';
import OnxChartContainer from '@/components/onx/charts/OnxChartContainer.vue';
import GaugeChart from '@/components/visual/chart/GaugeChart.vue';
import ViewChartSqlButton from '@/components/visual/chart/ViewChartSqlButton.vue';
import useEndDate from '@/composables/useEndDate';
import useMetricSource from '@/composables/useMetricSource';
import { useMetricSQL } from '@/composables/useMetricSQL';
import type { DateIntervalEnum } from '@/types/MetricRequestParameters';
import naiveId from '@/utils/naiveId';

export type Props = OnxChartBaseProps & {
  enabled?: boolean;
  geohashes: string[];
  transform: (data: any) => { datasets: (ChartDataset<'doughnut'> & { value: number })[] };
  nbDays?: number;
  dateInterval?: DateIntervalEnum;
  onExportToCsv?: (data: any, title: string) => void;
  horizontal?: boolean;
  max?: number;
  chartConfig?: any;
  chartTooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;
  chartTooltipShowSecondaryValue?: boolean;
  otherRequestParams?: AxiosRequestConfig['params'];
  gaugeChartAttrs?: Record<string, unknown>;
};

const defaultRed = getComputedStyle(document.documentElement).getPropertyValue('--error-500');
const defaultYellow = getComputedStyle(document.documentElement).getPropertyValue('--marigold-500');
const defaultGreen = getComputedStyle(document.documentElement).getPropertyValue('--success-300');

const props = withDefaults(defineProps<Props>(), {
  enabled: undefined,
  titleSuffix: '',
  titlePrefix: '',
});

const { chartTitleTooltip, metric } = toRefs(props);
const { currentEndDate } = useEndDate(props.dashboard);

const container = ref<ComponentPublicInstance | null>(null);

const query = props.queryFn({ endDate: currentEndDate });
const {
  query: { data: response, isLoading, isPending, isRefetching, isSuccess },
} = query;

const { metricSource } = useMetricSource(response);
const { sql } = useMetricSQL(response, isSuccess);

const chartData = computed(() => {
  if (isSuccess.value && response.value?.data) {
    const transformedData = props.transform(response.value.data);

    if (!transformedData) {
      return {
        datasets: [],
      };
    }

    if (!transformedData.datasets[0].backgroundColor) {
      transformedData.datasets[0].backgroundColor = [defaultRed, defaultYellow, defaultGreen];
    }

    return transformedData;
  } else {
    return {
      datasets: [],
    };
  }
});

const needleValue = computed(() => {
  if (chartData.value.datasets.length > 0) {
    return chartData.value.datasets[0].value;
  }

  return null;
});

const chartId = computed(() => `gauge-${metric.value}-${naiveId()}`);
</script>

<template>
  <OnxChartContainer
    class="onx-stacked-bar-chart"
    ref="container"
    :loading="isLoading || isRefetching || isPending"
    :metric-source="metricSource"
    :no-data="needleValue === null"
    :screenshot-legend="undefined"
    :screenshot-subtitle="screenshotSubtitle"
    :screenshot-title="screenshotTitle"
    :chart-title-tooltip="chartTitleTooltip"
    :subtitle="chartSubtitle"
    :title="chartTitle"
  >
    <GaugeChart
      v-if="needleValue != null"
      v-bind="gaugeChartAttrs"
      :chart-id="chartId"
      :chart-data="chartData"
      :needle-value="needleValue"
    />
    <template #tools v-if="sql">
      <ViewChartSqlButton :sql="sql" :chartTitle="chartTitle" />
    </template>
  </OnxChartContainer>
</template>

<style lang="scss"></style>
