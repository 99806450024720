<template>
  <div>
    <div class="line-chart-container" :style="{ height: `${height}px` }">
      <div class="LineChart">
        <canvas :id="chartId"></canvas>
        <ranking-table-tooltip
          v-if="!disableTooltip"
          :date="tooltip.date"
          :pos-class="tooltip.posClass"
          :tooltip-style="tooltip.tooltipStyle"
          :datapoints="tooltip.datapoints"
          :show-compare="showCompare"
          :display="tooltip.display"
          :title="title"
          :aggregation="aggregation"
          :unit="tooltip.unit"
        />
      </div>
      <div v-if="useCustomLegend" :id="legendContainerId" class="legend-container"></div>
    </div>
    <div :id="bottomPluginSlot" class="legend-container"></div>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import chroma from 'chroma-js';
import get from 'lodash/get';
import merge from 'lodash/merge';
import RankingTableTooltip from './RankingTableTooltip.vue';
import { X_AXIS_BASE_STYLE, Y_AXIS_BASE_STYLE, SCALE_TITLE_STYLE } from '@/constants/chartjs';
import { LATEST_STRING } from '@/constants/constants';
import {
  getDatapointsByDate,
  getTooltipState,
  getDateFromDatapoints,
  getLinechartRange,
  getDatasetLabel,
} from '@/utils/charts';
import htmlLegendPlugin from '@/components/visual/chart/line-chart-plugins/htmlLegendPlugin';

export default {
  name: 'LineChart',
  components: {
    RankingTableTooltip,
  },
  props: {
    chartId: {
      default: 'line-chart',
      type: String,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: Number,
      default: 400,
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    dataSet: {
      type: Array,
      default: () => [],
    },
    compareSet: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: undefined,
    },
    aggregation: {
      type: String,
      default: undefined,
    },
    scarceData: {
      type: Boolean,
      default: false,
    },
    noDecimals: {
      type: Boolean,
      default: false,
    },
    xAxisLabel: {
      type: String,
      default: undefined,
    },
    xAxisUnit: {
      type: String,
      default: undefined,
    },
    yAxisLabel: {
      type: String,
      default: undefined,
    },
    yAxisUnit: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    showConfidenceRate: {
      type: Boolean,
      default: false,
    },
    syncRange: {
      type: Object,
      default: () => ({}),
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    useCustomLegend: {
      type: Boolean,
      default: false,
    },
    showCompare: {
      type: Boolean,
      default: false,
    },
    yZoom: {
      type: Boolean,
      default: false,
    },
    showRankGaps: {
      type: Boolean,
      default: false,
    },
    showLineIcons: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    normalized: {
      type: Boolean,
      default: false,
    },
    minUnitDisplay: {
      type: String,
      default: 'month',
    },
    annotations: {
      type: Object,
      default: () => ({}),
    },
    spanGaps: {
      type: Boolean,
      default: true,
    },
  },
  chart: null,
  data() {
    return {
      tooltip: {
        datapoints: [],
        display: false,
        posClass: '',
        tooltipStyle: {},
        unit: '',
        date: undefined,
      },
      hiddenLegends: [],
    };
  },
  computed: {
    baseConfig() {
      return {
        type: 'line',
        data: {
          datasets: this.formattedDataSet,
          displayConfidenceRate: this.showConfidenceRate,
        },
        options: {
          spanGaps: this.spanGaps,
          normalized: this.normalized,
          animation: false,
          plugins: {
            datalabels: {
              display: false,
            },
            autocolors: false,
            annotation: {
              annotations: {
                box1: this.lineAnnotation,
                ...this.annotations,
              },
            },
            htmlLegend: {
              containerID: this.legendContainerId,
              events: ['mouseenter', 'mouseleave', 'click'],
            },
            lineChartLegendCdnListPlugin: {
              containerID: this.bottomPluginSlot,
            },
            legend: {
              display: !this.useCustomLegend && this.showLegend,
              position: 'bottom',
              labels: {
                filter: (item) => item.lineWidth,
                usePointStyle: false,
              },
            },

            tooltip: {
              enabled: false,
              mode: 'x',
              intersect: false,
              position: 'nearest',
              callbacks: {
                title: (item) => item,
              },
              external: (context) => {
                const tooltip = context.tooltip;
                const tooltipVisible = tooltip.title && tooltip.title.length > 0;
                const datapoints = getDatapointsByDate(
                  this.formattedDataSet,
                  get(tooltip, ['title', 0, 'raw', 'x']),
                  this.noDecimals,
                );

                if (tooltipVisible) {
                  const hiddenLabels = context.chart.legend.legendItems
                    .filter((item) => item.hidden)
                    .map((item) => item.text);
                  const validDataPoints = datapoints.filter((point) => {
                    return point.value != null && point.y != null && !hiddenLabels.includes(point.label);
                  });
                  this.tooltip = {
                    datapoints: validDataPoints,
                    ...getTooltipState(this.getChart(), tooltip),
                    date: getDateFromDatapoints(datapoints),
                  };
                }
                this.tooltip.display = !!tooltip.opacity;
                this.$emit('updateTooltip', this.tooltip);
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'x',
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ...X_AXIS_BASE_STYLE,
              ticks: {
                color: X_AXIS_BASE_STYLE.ticks.color,
                padding: X_AXIS_BASE_STYLE.ticks.padding,
              },
              type: 'time',
              time: {
                minUnit: this.minUnitDisplay,
                displayFormats: {
                  month: 'LLL d',
                },
              },
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.xAxisLabel,
                text: this.xAxisLabel,
              },
              grid: {
                offset: false,
                drawTicks: false,
              },
            },
            y: {
              ...Y_AXIS_BASE_STYLE,
              title: {
                ...SCALE_TITLE_STYLE,
                display: !!this.yAxisLabel,
                text: this.yAxisLabel,
              },
              grid: {
                offset: false,
                drawTicks: false,
              },
              ticks: {
                ...Y_AXIS_BASE_STYLE.ticks,
              },
              ...this.tickRange,
            },
          },
          onClick: this.handleClick,
        },
        plugins: this.useCustomLegend ? [htmlLegendPlugin(), ...this.plugins] : this.plugins,
      };
    },
    legendContainerId() {
      return `legend-container-for-${this.chartId}`;
    },
    bottomPluginSlot() {
      return `bottom-plugin-slot-for-${this.chartId}`;
    },
    hitRadius() {
      return this.scarceData ? 6 : 2;
    },
    tickRange() {
      if (!this.formattedDataSet.length) return {};
      if (this.syncRange && this.syncRange.suggestedMin) return this.syncRange;

      const arr = this.formattedDataSet
        .map((x) => x.data)
        .reduce((aac, val) => [...aac, ...val])
        .filter((x) => x.y)
        .map((x) => x.y);
      return getLinechartRange(Math.min(...arr), Math.max(...arr), 2);
    },
    formattedDataSet() {
      return this.dataSet.reduce((ac, set) => {
        const datasetLabel = getDatasetLabel(set.label);
        const showConfidenceRate = !this.hiddenLegends.includes(datasetLabel) && this.showConfidenceRate;

        ac.push({
          label: datasetLabel,
          borderWidth: 0,
          backgroundColor: showConfidenceRate ? this.hex2rgba(set.color) : 'transparent',
          fill: '+1',
          borderColor: showConfidenceRate ? this.hex2rgba(set.color) : 'transparent',
          pointRadius: 0,
          pointHitRadius: this.hitRadius,
          pointHoverRadius: 0,
          filterOut: true,
          filterBy: 'uci',
          lineTension: 0,
          data: set.data.map((p) => {
            return {
              y: p.uci,
              x: p.x,
            };
          }),
          operator: set.operator,
        });

        ac.push({
          label: datasetLabel,
          borderWidth: 0,
          backgroundColor: 'transparent',
          borderColor: showConfidenceRate ? this.hex2rgba(set.color) : 'transparent',
          pointRadius: 0,
          pointHitRadius: this.hitRadius,
          pointHoverRadius: 0,
          filterOut: true,
          filterBy: 'lci',
          lineTension: 0,
          data: set.data.map((p) => {
            return {
              y: p.lci,
              x: p.x,
            };
          }),
        });

        if (this.showLineIcons) {
          // add a circle at the start of the line to the left with an outline and a label
          ac.push({
            label: set.label[0].toUpperCase(),
            borderWidth: 2,
            backgroundColor: set.backgroundColor,
            borderColor: set.color,
            data: [set.data[0]],
            filterOut: true,
            radius: 5,
          });
        }

        const compareDataSet =
          this.compareSet && this.compareSet.find((compareOperatorData) => compareOperatorData.label === set.label);

        if (!this.showRankGaps) {
          ac.push({
            label: datasetLabel,
            borderWidth: 2,
            backgroundColor: 'transparent',
            borderColor: set.color,
            pointBorderColor: 'rgba(0,0,0,0)',
            pointBackgroundColor: 'rgba(0,0,0,0)',
            pointHoverBackgroundColor: 'rgba(0,0,0,0)',
            pointRadius: 4,
            pointHitRadius: this.hitRadius,
            pointHoverRadius: 8,
            pointStyle: 'circle',
            lineTension: 0,
            data: set.data,
            compare: this.showCompare ? compareDataSet : null,
            operator: set.operator,
          });
        } else {
          const breakpoints = [0];

          for (let i = 1; i < set.data.length; i++) {
            if ((!set.data[i - 1].rank || !set.data[i].rank) && set.data[i - 1].rank !== set.data[i].rank) {
              breakpoints.push(i);
            }
          }
          breakpoints.push(set.data.length);

          for (let i = 0; i < breakpoints.length - 1; i++) {
            ac.push({
              label: datasetLabel,
              borderWidth: 2,
              operatorColor: set.color,
              backgroundColor: 'transparent',
              borderColor: set.data[breakpoints[i]].rank ? set.color : this.hex2rgba(set.color, 0.4),
              pointBorderColor: 'rgba(0,0,0,0)',
              pointBackgroundColor: 'rgba(0,0,0,0)',
              pointHoverBackgroundColor: 'rgba(0,0,0,0)',
              pointRadius: 4,
              pointHitRadius: this.hitRadius,
              pointHoverRadius: 8,
              pointStyle: 'circle',
              lineTension: 0,
              borderDash: set.data[breakpoints[i]].rank ? undefined : [6, 6],
              data: set.data.slice(breakpoints[i], breakpoints[i + 1] + 1),
              compare: this.showCompare ? compareDataSet : null,
              operator: set.operator,
            });
          }
        }

        if (this.showCompare) {
          if (!compareDataSet) return ac;

          ac.push({
            label: `national-${compareDataSet.label}`,
            borderWidth: 0,
            backgroundColor: showConfidenceRate ? this.hex2rgba(compareDataSet.color) : 'transparent',
            // lines need to draw on top, uncomment for testing
            // backgroundColor: 'black',
            fill: '+1',
            borderColor: showConfidenceRate ? this.hex2rgba(compareDataSet.color) : 'transparent',
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            filterOut: true,
            lineTension: 0,
            data: compareDataSet.data.map((p) => {
              return {
                y: p.lci,
                x: p.x,
              };
            }),
          });

          ac.push({
            label: `national-${compareDataSet.label}`,
            borderWidth: 2,
            backgroundColor: this.hex2rgba(compareDataSet.color),
            // lines need to draw on top, uncomment for testing
            // backgroundColor: 'black',
            fill: showConfidenceRate ? '+1' : null,
            borderColor: compareDataSet.color,
            pointBorderColor: 'rgba(0,0,0,0)',
            pointBackgroundColor: 'rgba(0,0,0,0)',
            pointHoverBackgroundColor: 'rgba(0,0,0,0)',
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 8,
            filterOut: true,
            pointStyle: 'circle',
            lineTension: 0,
            borderDash: [2, 10],
            data: compareDataSet.data,
          });

          ac.push({
            label: `national-${compareDataSet.label}`,
            borderWidth: 0,
            backgroundColor: 'rgba(0,0,0,0)',
            borderColor: showConfidenceRate ? this.hex2rgba(compareDataSet.color) : 'transparent',
            pointRadius: 0,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            filterOut: true,
            lineTension: 0,
            data: compareDataSet.data.map((p) => {
              return {
                y: p.uci,
                x: p.x,
              };
            }),
          });
        }

        return ac;
      }, []);
    },
    datasetMax() {
      if (this.formattedDataSet.length === 0) {
        return 0;
      }
      return Math.max(...this.formattedDataSet.map((set) => set.data.length));
    },
    longestSet() {
      return this.findLongestSet(this.formattedDataSet);
    },
    lineAnnotation() {
      if (!this.date || this.date === LATEST_STRING) {
        return null;
      }
      return {
        type: 'line',
        xMin: this.date,
        xMax: this.date,
        borderWidth: 12,
        borderColor: 'rgba(157,157,201, .2)',
        backgroundColor: 'rgba(157,157,201, .2)',
      };
    },
  },
  watch: {
    dataSet() {
      const chart = this.getChart();
      if (this.dataSet && chart) {
        this.hiddenLegends = [];
        chart.data.datasets = this.formattedDataSet;
        this.tooltip.tooltip = [];
        // Safari doesn't handle this update automatically unlike chrome
        chart.options.scales.y.title.text = this.yAxisLabel;

        this.updateYZoom();

        chart.options.plugins.annotation.annotations.box1 = this.lineAnnotation;
        chart.update();
      }
    },
    showConfidenceRate() {
      this.updateDataset();
    },
    yZoom() {
      this.updateYZoom();
      this.getChart().update();
    },
    hiddenLegends() {
      this.updateDataset();
    },
  },
  mounted() {
    setTimeout(() => {
      this.renderChart();
    }, 1);
  },
  methods: {
    updateDataset() {
      const chart = this.getChart();
      const hiddenLegends = this.hiddenLegends;
      chart.data.datasets = this.formattedDataSet;
      if (!hiddenLegends.length) {
        chart.update();
        return;
      }

      hiddenLegends.forEach((legend) => {
        const index = chart.data.datasets.findIndex((set) => set.label === legend && !set.filterOut);
        chart.getDatasetMeta(index).hidden = true;
      });

      chart.data.displayConfidenceRate = this.showConfidenceRate;
      chart.update();
    },
    findLongestSet(sets) {
      return sets.find((set) => set.data.length === this.datasetMax);
    },
    getChart() {
      return Chart.getChart(this.chartId);
    },
    renderChart() {
      const mergedConfig = merge({ ...this.baseConfig }, { ...(this.config || {}) });

      const tooltipPlugin = get(this.config, 'options.plugins.tooltip.external');
      if (typeof tooltipPlugin === 'function') {
        mergedConfig.options.plugins.tooltip.external = (context) => {
          tooltipPlugin(context, this.tooltip);
        };
      }

      new Chart(this.chartId, mergedConfig);
    },
    hex2rgba(hex, alpha = '0.1') {
      const [red, green, blue] = chroma(hex).rgb();
      return `rgba(${red},${green},${blue}, ${alpha})`;
    },
    lightRgb(hex) {
      const [red, green, blue] = chroma(hex).rgb();

      const deltaRed = (255 - red) * 0.9;
      const deltaGreen = (255 - green) * 0.9;
      const deltaBlue = (255 - blue) * 0.9;

      return `rgb(${red + deltaRed},${green + deltaGreen},${blue + deltaBlue})`;
    },
    handleClick(event, _, chart) {
      const legendYStart = chart.height - chart.boxes[0].height;
      const clickedLegendArea = event.layerY >= legendYStart;
      if (clickedLegendArea) {
        return;
      }

      const activeElement = chart.getElementsAtEventForMode(event, 'index', { intersect: false }, false);

      if (activeElement.length) {
        const set = this.formattedDataSet[activeElement[0].datasetIndex];
        const point = set.data[activeElement[0].index];

        this.$emit('point', point.x);
      } else {
        const day = 1000 * 60 * 60 * 24;
        const pos = event.offsetX - chart.scales.y.width;
        const width = chart.width - chart.scales.y.width - 20; // magic padding
        // we floor because we count full intevals only
        // // we add one because points are at both ends of intervals, so points will always be intervals + 1
        const count = Math.floor((chart.scales.x.max - chart.scales.x.min) / day) + 1;
        const scale = width / count;
        const el = pos / scale;
        const nrOfDaysOnChart = Math.ceil(el) - 1;
        const point = this.longestSet.data[nrOfDaysOnChart];

        if (point) {
          this.$emit('point', point.x);
        }
      }
    },
    updateYZoom() {
      if (this.yZoom) {
        delete this.getChart().options.scales.y.suggestedMax;
        delete this.getChart().options.scales.y.suggestedMin;
      } else {
        this.getChart().options.scales.y.suggestedMax = this.tickRange.suggestedMax;
        this.getChart().options.scales.y.suggestedMin = this.tickRange.suggestedMin;
      }
    },
  },
};
</script>

<style lang="scss">
.line-chart-container {
  display: flex;
  align-items: stretch; // make sure the legend at the right has access to the whole height of the chart, to reduce unnecessary scrolling

  .LineChart {
    flex-grow: 1;
    min-width: 0;
  }

  .legend-container {
    font-size: 14px;
  }
}

ul.legend-list {
  margin: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  padding-bottom: 1rem;

  li {
    display: flex;
    align-items: center;
    margin-left: 8px;
    gap: 4px;

    &.inline {
      display: inline-flex;
    }

    &.disabled {
      text-decoration: line-through;
      opacity: 0.5;
    }

    & .legend-item-color {
      flex-shrink: 0;
      height: 12px;
      width: 24px;
    }

    & p {
      margin: 0;
      padding: 0;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    transition: opacity 100ms ease;
    will-change: opacity;
  }

  &.highlight-on-hover {
    cursor: pointer;

    &:hover li:not(:hover) {
      opacity: 0.5;
    }
  }
}
</style>
