import { createNamespacedHelpers, useGetters } from 'vuex-composition-helpers';
import { computed } from 'vue';
import { getScoreChanges } from '@/utils/score';
import { METRIC_STRUCTURE_TO_VALUE_FIELD_MAP } from '@/constants/constants';
import { metricToFullTitle } from '@/utils/titles';
import router from '@/router';

const useMetricBoxData = (storeName) => {
  const { useGetters: useLocationGetters } = createNamespacedHelpers('location');
  const { useGetters: useStatsGetters } = createNamespacedHelpers(storeName);

  const { currentLocation } = useLocationGetters(['currentLocation']);
  const { loadingMetrics } = useStatsGetters(['loadingMetrics']);
  const { chartPending } = useStatsGetters(['chartPending']);
  const { operators } = useGetters(['operators']);
  const { dashboardInfo } = useGetters(['dashboardInfo']);

  const homeNetwork = computed(() => {
    return dashboardInfo.value.home_networks.find((n) => {
      return n.country_iso3 === currentLocation.value.iso3;
    });
  });

  const createMetricBoxData = (ranksSource, selectedMetric, compareTo) => {
    const actualCompareTo = compareTo || router.currentRoute.value.params?.compareTo;
    const homeOperatorRanksSource = (ranksSource || []).find(
      (o) => o.canonical_network_id === homeNetwork.value?.canonical_network_id,
    );

    return {
      metricKind: selectedMetric.kind,
      metricType: selectedMetric.type,
      metric: selectedMetric.key,
      metricUnit: selectedMetric.units?.short,
      metricCategory: selectedMetric.category,
      biggerBetter: selectedMetric.bigger_is_better,
      isAwardMetric: selectedMetric.isAwardMetric,
      metricUserGroup: selectedMetric.user_group,
      title: metricToFullTitle(selectedMetric),
      granularity: currentLocation.value.granularityId,
      loading: (loadingMetrics.value || []).includes(selectedMetric.key) || chartPending.value,
      byConnectionDisabled: selectedMetric.category === 'ecq' || selectedMetric.category === 'ccq',
      ranks: (ranksSource || []).map((p) => {
        p.operator = p.operator || operators.find((operator) => operator.id === p.canonical_network_id);

        return {
          lci: p.lci,
          operatorColor: p.operator.hex_color ? `#${p.operator.hex_color}` : '#DEE4EC',
          operatorInitial: p.operator.name_mapped[0],
          operatorName: p.operator.name_mapped,
          operatorIsMvno: p.operator.is_mvno,
          canonicalNetworkId: p.operator.canonical_network_id,
          // at the time of this writing, MVNOs are not ranked and their rank is set to 0
          // ranks normally start at 1
          // MVNOs will be ranked in time, so 0 will change to >= 1
          // that's why this does not check is_mvno, but rather only for the placeholder 0
          rank: p.rank === 0 ? null : p.rank,
          uci: p.uci,
          value: p[METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[selectedMetric.structure]] || p.value,
          previous: p.previous && p.previous[actualCompareTo] && p.previous[actualCompareTo].mean,
          scoreChange: getScoreChanges(
            p,
            homeOperatorRanksSource,
            METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[selectedMetric.structure],
            actualCompareTo,
            p.canonical_network_id === homeNetwork.value?.canonical_network_id,
          ),
          ...(p.comparison && p.comparison[actualCompareTo]
            ? {
                comparisonRank: p.comparison[actualCompareTo].rank,
                comparisonMean:
                  p.comparison[actualCompareTo][METRIC_STRUCTURE_TO_VALUE_FIELD_MAP[selectedMetric.structure]],
              }
            : {}),
        };
      }),
    };
  };

  return {
    createMetricBoxData,
  };
};

export default useMetricBoxData;
