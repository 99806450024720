<template>
  <NetworksMenuInfoModal v-model:displayModal="displayModal" />
  <template v-if="singleOperatorMode">
    <FieldGroup v-if="hasMNOs">
      <template #title>
        <label class="onx-networks-label">
          <span>MNOs</span>
          <CustomTooltip message="" placement="bottom" @click.prevent="displayNetworkModal">
            <InfoIcon class="onx-tristatebox__tooltipIcon" />
          </CustomTooltip>
        </label>
      </template>
      <RadioButtons
        container-class-name="onx-networks-menu"
        group-name="mnos"
        :options="mnos"
        value-prop="canonical_network_id"
        label-prop="name_mapped"
        :selected-option="selected?.canonical_network_id"
        @select="changeSelection"
      />
    </FieldGroup>
    <FieldGroup v-if="hasMVNOs">
      <template #title>
        <label class="onx-networks-label">
          <span>MVNOs</span>
          <CustomTooltip message="" placement="bottom" @click.prevent="displayNetworkModal">
            <InfoIcon class="onx-tristatebox__tooltipIcon" />
          </CustomTooltip>
        </label>
      </template>
      <RadioButtons
        container-class-name="onx-networks-menu"
        group-name="mvnos"
        :options="mvnos"
        value-prop="canonical_network_id"
        label-prop="name_mapped"
        :selected-option="selected?.canonical_network_id"
        @select="changeSelection"
      />
    </FieldGroup>
  </template>
  <template v-else>
    <FieldGroup v-if="hasMNOs">
      <template #title>
        <OnxTriStateBox
          label="MNOs"
          :model-value="operatorTristateValue(this.mnos)"
          @check="selectOperators(this.mnos)"
          @uncheck="unselectOperators(this.mnos)"
        >
          <label class="onx-networks-label">
            <span>MNOs</span>
            <CustomTooltip placement="bottom" @click.stop="displayNetworkModal">
              <InfoIcon class="onx-tristatebox__tooltipIcon" />
            </CustomTooltip>
          </label>
        </OnxTriStateBox>
      </template>
      <ul class="onx-networks-menu">
        <li
          v-for="network in mnos"
          :key="network.canonical_network_id"
          class="onx-networks-menu__operator"
          :class="{ 'onx-networks-menu__operator--disabled': !isToggleable(network) }"
        >
          <OnxCheckbox
            :model-value="network.selected"
            :label="network.name_mapped"
            :disabled="!isToggleable(network)"
            dark
            @click.prevent="changeSelection(network.canonical_network_id)"
          />
        </li>
      </ul>
    </FieldGroup>
    <FieldGroup v-if="hasMVNOs">
      <template #title>
        <OnxTriStateBox
          label="MVNOs"
          :model-value="operatorTristateValue(this.mvnos)"
          @check="selectOperators(this.mvnos)"
          @uncheck="unselectOperators(this.mvnos)"
        >
          <label class="onx-networks-label">
            <span>MVNOs</span>
            <CustomTooltip message="" placement="bottom" @click.stop="displayNetworkModal">
              <InfoIcon class="onx-tristatebox__tooltipIcon" />
            </CustomTooltip>
          </label>
        </OnxTriStateBox>
      </template>
      <ul class="onx-networks-menu">
        <li
          v-for="network in mvnos"
          :key="network.canonical_network_id"
          class="onx-networks-menu__operator"
          :class="{ 'onx-networks-menu__operator--disabled': !isToggleable(network) }"
        >
          <OnxCheckbox
            :model-value="network.selected"
            :label="network.name_mapped"
            :disabled="!isToggleable(network)"
            dark
            @click.prevent="changeSelection(network.canonical_network_id)"
          />
        </li>
      </ul>
    </FieldGroup>
  </template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import useUser from '../onx/composables/useUser';
import InfoIcon from '../onx/icons/InfoIcon.vue';
import OnxTriStateBox from '../onx/OnxCheckbox/OnxTriStateBox.vue';
import FieldGroup from '@/components/menu/FieldGroup.vue';
import RadioButtons from '@/components/menu/RadioButtons.vue';
import OnxCheckbox from '@/components/onx/OnxCheckbox';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import NetworksMenuInfoModal from '@/components/competitive/NetworksMenuInfoModal.vue';
import router from '@/router';

export default {
  name: 'NetworksMenu',
  components: {
    OnxCheckbox,
    FieldGroup,
    RadioButtons,
    OnxTriStateBox,
    CustomTooltip,
    NetworksMenuInfoModal,
    InfoIcon,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
    singleOperatorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxNetworksDisplayed: 7,
      mnoTooltip: 'Mobile Network Operator.',
      mvnoTooltip: 'Mobile Virtual Network Operator and Operator Sub-brands.',
      displayModal: false,
    };
  },
  setup() {
    const { user } = useUser();
    return {
      user,
    };
  },
  computed: {
    ...mapGetters({
      availableNetworks: 'charts/networks',
      compare: 'competitive/compare',
      homeNetwork: 'charts/homeNetwork',
      hiddenNetworkIds: 'charts/hiddenNetworksIds',
      isMobile: 'page/isMobile',
    }),
    selected() {
      if (this.singleOperatorMode) {
        const selectedNetworkId = parseInt(router.currentRoute.value.params.network, 10);
        return this.availableNetworks.find((network) => network.canonical_network_id === selectedNetworkId);
      }

      return this.availableNetworks.filter((network) => network.selected);
    },
    isActive() {
      return this.singleOperatorMode ? !this.compare : this.compare;
    },
    hasMNOs() {
      return this.mnos.length > 0;
    },
    mnos() {
      return this.availableNetworks.filter((network) => !network.is_mvno);
    },
    hasMVNOs() {
      return this.mvnos.length > 0 && this.user?.include_mvno === true;
    },
    mvnos() {
      return this.availableNetworks.filter((network) => network.is_mvno);
    },
  },
  methods: {
    ...mapActions(['toggleOperator', 'hideOperators', 'showOperators']),
    itemIsActive(network, selected) {
      return this.singleOperatorMode ? parseInt(router.currentRoute.value.params.network) === network : selected;
    },
    changeSelection(canonicalNetworkId) {
      if (this.singleOperatorMode) {
        router.push({
          name: router.currentRoute.value.name,
          params: {
            ...router.currentRoute.value.params,
            network: canonicalNetworkId.toString(),
          },
        });
      } else {
        if (!this.compare && this.changeMode !== undefined) {
          this.changeMode();
        }
        this.toggleOperator(canonicalNetworkId);
      }
    },
    isHomeNetwork(network) {
      return this.homeNetwork && network.canonical_network_id === this.homeNetwork.canonical_network_id;
    },
    isToggleable(network) {
      if (!network.selected) {
        // can select any unselected network
        return true;
      } else if (this.isHomeNetwork(network)) {
        // cannot unselect home network
        return false;
      } else {
        // can remove any network if there are more than one selected
        return this.selected.length > 1;
      }
    },
    /**
     * Returns the tristate value of the operators: all checked, all unchecked, or some unchecked
     * @param {*} operators Operator[]
     */
    operatorTristateValue(operators) {
      const selectedOperators = (operators || []).filter((operator) => operator.selected);
      if (selectedOperators.length === 0) {
        return 'unchecked';
      } else if (selectedOperators.length === operators.length) {
        return 'checked';
      } else {
        return 'indeterminate';
      }
    },
    /**
     * @param {*} operators Operator[]
     */
    selectOperators(operators) {
      if (!this.singleOperatorMode) {
        const networkIDs = [...new Set(operators.map((operator) => operator.canonical_network_id))];
        this.showOperators(networkIDs);
      }
    },
    /**
     * @param {*} operators Operator[]
     */
    unselectOperators(operators) {
      if (!this.singleOperatorMode) {
        const networkIDs = operators
          .filter((operator) => !this.isHomeNetwork(operator)) // cannot unselect home network
          .map((operator) => operator.canonical_network_id);
        this.hideOperators(networkIDs);
      }
    },
    /**
     * The modal can hide itself; we only need to show it
     */
    displayNetworkModal() {
      this.displayModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';

.onx-single-operator-toggle {
  color: white;
}
.onx-networks-label {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: pxToRem(12);
  line-height: pxToRem(24);
  height: pxToRem(24);
  color: white;
}
.onx-networks-menu {
  display: flex;
  flex-direction: column;
  color: var(--white);
  font-size: pxToRem(14);
  list-style: none;
  padding-inline-start: 9px; /* 10px minus the left border */
  border-left: 1px solid var(--onx-checkbox-on-color);
  margin: 0;
  margin-bottom: 2px;

  /* Remove the additional padding before the radio buttons */
  .onx-radio-button__input {
    margin-left: 0;
  }

  &__operator {
    height: 24px;
    display: inline-flex;
    align-items: center;

    &--disabled {
      color: var(--charcoal-300);
    }
  }
}
.onx-networks-label .onx-tristatebox__tooltipIcon {
  color: var(--charcoal-200);
}
</style>
