import { Column } from '@/components/onx/table';
import { CongestionUnderutilizedCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionUnderutilizedCellsForTable';

import { toFixedNoZeroPad } from '@/utils/helpers';
import { ascNumberSort } from '@/utils/sorting';

export const congestionUnderutilizedCellsColumns: Column<CongestionUnderutilizedCellsRow>[] = [
  {
    value: (row) => row.operatorName,
    header: 'Operator',
    key: 'operator',
    filterable: true,
  },
  {
    value: (row) => row.enodebid,
    header: 'eNodeB ID',
    key: 'connection_enodebid',
  },
  {
    value: (row) => row.cellId,
    header: 'Cell ID',
    key: 'connection_cid',
  },
  {
    value: (row) => row.connectionCategory,
    header: 'Connection Category',
    key: 'connection_category',
  },
  {
    value: (row) => row.capacityScore,
    cell: (row) => (row.capacityScore != null ? Math.round(row.capacityScore) : ''),
    header: 'Capacity Score',
    key: 'capacity_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downloadThroughput,
    cell: (row) => (row.downloadThroughput != null ? toFixedNoZeroPad(row.downloadThroughput, 1) : ''),
    header: 'Download Throughput (Mbps)',
    key: 'download_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.uploadThroughput,
    cell: (row) => (row.uploadThroughput != null ? toFixedNoZeroPad(row.uploadThroughput, 1) : ''),
    header: 'Upload Throughput (Mbps)',
    key: 'upload_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.signalStrength,
    cell: (row) => (row.signalStrength != null ? Math.round(row.signalStrength) : ''),
    header: 'Signal Strength (dBm)',
    key: 'signal_strength',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.packetLossAndDiscard,
    cell: (row) => (row.packetLossAndDiscard != null ? toFixedNoZeroPad(row.packetLossAndDiscard, 1) : ''),
    header: 'Packet Loss & Discard (%)',
    key: 'packet_loss_and_discard',
    sort: ascNumberSort,
  },
];
