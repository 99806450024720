import { type AxiosRequestConfig } from 'axios';

import { MetricSubtypes } from '@/types/MetricSubtypes';
import { ChartTypesEnum } from '@/types/Charts';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import { Column } from '@/components/onx/table';

export enum InitialSortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum LoopOverOptions {
  ConnectionCategories = 'connection-categories',
  Operators = 'operators',
}

export type MetricDefinitionWithSingleSubtype = {
  metricSubtype: MetricSubtypes;
};

type ChartMetricDefinitionBase = {
  structure?: MetricStructuresEnum;
  label?: string;
  connectionCategory?: string;
  id?: string;
  chartGroup?: MetricSubtypes;
  chartAttrs?: Record<string, unknown> & {
    chartTitlePlaceholders?: string;

    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };
  chartContainerClass?: string;
  transformData?: (data: any) => any;
  tooltipPlugin?: (context: any, tooltip: any, chartData: any) => void;
  onExportCsv?: (...args: any[]) => any;
  exportAttrs?: Record<string, unknown>;
  requestParams?: AxiosRequestConfig['params'];
  loopOver?: LoopOverOptions[];
};

type OtherChartTypes = Exclude<ChartTypesEnum, ChartTypesEnum.Table>;

type OtherChartMetricDefinition = {
  chartType: OtherChartTypes;
};

type TableChartMetricDefinition = {
  chartType: ChartTypesEnum.Table;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: InitialSortDirection;
};

type DatasetChartMetricDefinition = ChartMetricDefinitionBase & {
  dataset: string;
  chartType: ChartTypesEnum;
  metricSubtype?: never;
};

export type DatasetTableChartMetricDefinition = ChartMetricDefinitionBase & {
  dataset: string;
  chartType: ChartTypesEnum.DatasetTable;
  columns: Column<any>[];
  initialSortColumnKey?: string;
  initialSortDirection?: InitialSortDirection;

  chartAttrs: {
    chartTitlePlaceholders: string;

    /** Renders a tooltip icon beside the title header of a chart */
    chartTitleTooltip?: string;
  };
  metricSubtype?: never;
};

export type ChartMetricDefinitionByChartTypes = ChartMetricDefinitionBase &
  (OtherChartMetricDefinition | TableChartMetricDefinition);
export type ChartMetricDefinitionWithSingleSubtype = ChartMetricDefinitionByChartTypes &
  MetricDefinitionWithSingleSubtype;
export type ChartMetricDefinition =
  | ChartMetricDefinitionWithSingleSubtype
  | DatasetChartMetricDefinition
  | DatasetTableChartMetricDefinition;

export type ChartMetricDefWithConnectionCategory = ChartMetricDefinition & {
  connectionCategory: string;
};

export const isChartMetricDefinitionWithSingleSubtype = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return 'metricSubtype' in definition;
};

export const isTableChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is MetricDefinitionWithSingleSubtype & ChartMetricDefinitionBase & TableChartMetricDefinition => {
  return isChartMetricDefinitionWithSingleSubtype(definition) && definition.chartType === ChartTypesEnum.Table;
};

export const isGaugeChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionWithSingleSubtype => {
  return definition.chartType === ChartTypesEnum.Gauge;
};

export const isDatasetChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is DatasetChartMetricDefinition => {
  return 'dataset' in definition;
};

export const isDatasetTableChartMetricDefinition = (
  definition: ChartMetricDefinition,
): definition is ChartMetricDefinitionBase & DatasetTableChartMetricDefinition => {
  return isDatasetChartMetricDefinition(definition) && definition.chartType === ChartTypesEnum.DatasetTable;
};

export const loopsForEachConnectionCategory = (definition: ChartMetricDefinition): boolean => {
  return Boolean(definition.loopOver?.includes(LoopOverOptions.ConnectionCategories));
};

export const loopsForEachOperator = (definition: ChartMetricDefinition): boolean => {
  return Boolean(definition.loopOver?.includes(LoopOverOptions.Operators));
};
